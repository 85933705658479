import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ApproveSvgCompontIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1460_19241)">
        <path d="M10 0.894531C4.78125 0.894531 0.5625 5.11328 0.5625 10.332C0.5625 15.5508 4.78125 19.8008 10 19.8008C15.2188 19.8008 19.4688 15.5508 19.4688 10.332C19.4688 5.11328 15.2188 0.894531 10 0.894531ZM10 18.3945C5.5625 18.3945 1.96875 14.7695 1.96875 10.332C1.96875 5.89453 5.5625 2.30078 10 2.30078C14.4375 2.30078 18.0625 5.92578 18.0625 10.3633C18.0625 14.7695 14.4375 18.3945 10 18.3945Z" fill="#008562" />
        <path d="M12.6875 7.42595L8.96874 11.051L7.28124 9.3947C6.99999 9.11345 6.56249 9.1447 6.28124 9.3947C5.99999 9.67595 6.03124 10.1135 6.28124 10.3947L8.28124 12.3322C8.46874 12.5197 8.71874 12.6135 8.96874 12.6135C9.21874 12.6135 9.46874 12.5197 9.65624 12.3322L13.6875 8.4572C13.9687 8.17595 13.9687 7.73845 13.6875 7.4572C13.4062 7.17595 12.9687 7.17595 12.6875 7.42595Z" fill="#008562" />
      </g>
      <defs>
        <clipPath id="clip0_1460_19241">
          <rect width="20" height="20" fill="white" transform="translate(0 0.332031)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApproveSvgCompontIcon;

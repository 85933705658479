import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Hidden,
  Box,
  Grid,
  GridList,
  Typography,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
  useMediaQuery,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputAndPopper, CCOrdersCard } from 'src/components';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ordersApi } from 'src/services/orders';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import NoOrdersIcon from 'src/assets/no_orders.svg';
import { getSelectedStoreAppClipIds, goToOrderDetails } from 'src/utils';
import SwipeableViews from 'react-swipeable-views';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    background: '#fff',
  },
  bodyContainer: {
    width: '100%',
    background: '#fff',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    flex: 1,
    overflow: 'hidden',
    paddingBottom: 150,
    background: '#F3F4F6',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    borderTop: '1px solid var(--Stroke, #DFE4EA)',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
    '& .MuiGrid-container': {
      height: 'auto !important',
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },

  appBar: {
    marginTop: 10,
    backgroundColor: '#FFFFFF',
  },
  tabStyle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}));

const ClickAndCollect = () => {
  const classes = useStyles();
  const intl = useIntl();
  const theme: any = useTheme();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const [ordersData, setOrdersData] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  let smallText = useMediaQuery('(max-width:450px)');

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };
  const {
    selected_regions,
    selected_retailers,
    active_stores,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);

  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { ccOrders: page = 1 } = pageNumber;

  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        ccOrders: value,
      },
    });
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      include_demo_stores,
      include_inactive_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let params: any = {
      v1: 'new_base_v4',
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      page,
      purchase_type: 'click_and_collect',
      token: cookies.app_token,
      include_demo: include_demo_stores,
      include_inactive: include_inactive_stores,
      page_size: pageSize,
    };

    if (value > 0) {
      params['cc_order_status'] = getCCOrderStatus(value);
    }
    return ordersApi.query(params);
  };

  let { isLoading, mutate: fetchOrders } = useMutation(
    [
      'ccOrders',
      page,
      selected_regions,
      selected_retailers,
      active_stores,
      value,
      pageSize,
    ],
    fetchOrdersData,
    {
      onSuccess: (data: any) => {
        console.log('useMutation');
        setOrdersData(data);
      },
    },
  );
  const {
    isLoading: fetchOrdersLoading,
    data: orderData,
    isFetching,
    error,
  } = useQuery(
    [
      'ccOrders',
      page,
      selected_regions,
      selected_retailers,
      active_stores,
      value,
      pageSize,
    ],
    fetchOrdersData,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        setOrdersData(data);
      },
    },
  );

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    handlePagination({}, 1);
  };

  const handleChangeIndex = (index: any) => {
    setValue(index);
  };

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const getCCOrderStatus = (index: any) => {
    switch (index) {
      case 1:
        return 'created';
      case 2:
        return 'accepted';
      case 3:
        return 'ready_to_collect';
      default:
        return 'created';
    }
  };

  const getList = (value: any, index: any) => {
    return (
      value === index && (
        <>
          {(fetchOrdersLoading || isFetching) && (
            <Box className={classes.noOrdersContainer}>
              <CircularProgress color="primary" size={20} />
            </Box>
          )}
          {!isLoading && get(ordersData, 'data.data', []).length === 0 && (
            <Box className={classes.noOrdersContainer}>
              <Box className={classes.noOrders}>
                <Box className={classes.iconContainer}>
                  <img className={classes.icon} src={NoOrdersIcon} alt="" />
                </Box>
                <Typography className={classes.noOrdersText}>
                  No orders so far
                </Typography>
              </Box>
            </Box>
          )}
          {isLoading && (
            <Box className={classes.noOrdersContainer}>
              <CircularProgress color="primary" size={30} />
            </Box>
          )}
          <Box component="div" className={classes.body}>
            <GridList className={classes.gridContainer} cols={1}>
              {get(ordersData, 'data.data', []).map(
                (item: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={4}
                  >
                    <CCOrdersCard item={item} />
                  </Grid>
                ),
              )}
            </GridList>
          </Box>
        </>
      )
    );
  };

  return (
    <Paper className={classes.root}>
      <Header
        showStores={true}
        title={
          <FormattedMessage
            id="click&Collect"
            defaultMessage="Click And Collect"
          />
        }
        order_type="click_and_collect"
      />
      <Hidden only={['lg', 'xl']}>
        <Box className={classes.input}>
          <InputAndPopper order_type="click_and_collect" />
        </Box>
      </Hidden>
      <Box component="div" className={classes.bodyContainer}>
        <AppBar
          position="static"
          color="default"
          className={classes.appBar}
          elevation={0}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              className={classes.tabStyle}
              label={
                smallText ? 'ALL' : intl.formatMessage({ id: 'allOrders' })
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                smallText ? 'NEW' : intl.formatMessage({ id: 'newOrders' })
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                smallText
                  ? 'PROGRESS'
                  : intl.formatMessage({ id: 'inProgress' })
              }
              {...a11yProps(2)}
            />
            <Tab
              label={
                smallText
                  ? 'COLLECT'
                  : intl.formatMessage({ id: 'readyToCollect' })
              }
              {...a11yProps(3)}
            />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <Box
            role="tabpanel"
            hidden={value !== 0}
            id={`full-width-tabpanel-${0}`}
            aria-labelledby={`full-width-tab-${0}`}
          >
            {getList(value, 0)}
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 1}
            id={`full-width-tabpanel-${1}`}
            aria-labelledby={`full-width-tab-${1}`}
          >
            {getList(value, 1)}
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 2}
            id={`full-width-tabpanel-${2}`}
            aria-labelledby={`full-width-tab-${2}`}
          >
            {getList(value, 2)}
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 3}
            id={`full-width-tabpanel-${3}`}
            aria-labelledby={`full-width-tab-${3}`}
          >
            {getList(value, 3)}
          </Box>
        </SwipeableViews>

        {get(ordersData, 'data.data', []).length > 0 && (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
          >
            <Hidden only={['xs', 'sm']}>
              <Box
                style={{
                  display: 'inline-flex',
                  position: 'absolute',
                  left: 0,
                }}
              >
                <InputLabel
                  htmlFor="age-simple"
                  style={{
                    margin: 'auto 10px auto 30px',
                    fontWeight: 500,
                    color: theme.palette.color_dark_text.main,
                  }}
                >
                  Items per page:
                </InputLabel>
                <Select
                  style={{
                    marginRight: 8,
                    background: 'transparent',
                    borderRadius: 6,
                    border: '1px solid var(--Dark-Dark-7, #D1D5DB)',
                  }}
                  value={pageSize}
                  onChange={(event: any) => {
                    dispatch({
                      type: 'CHANGE_ROWS_PER_PAGE',
                      payload: event.target.value,
                    });
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Box>
            </Hidden>
            <Snackbar
              open={toast?.visible}
              autoHideDuration={3000}
              onClose={hideToast}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert onClose={hideToast} severity={toast?.severity}>
                {toast?.message}
              </Alert>
            </Snackbar>
            <Pagination
              count={Math.ceil(get(ordersData, 'data.count', 10) / pageSize)}
              shape="rounded"
              size="medium"
              color="primary"
              page={page}
              onChange={handlePagination}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default memo(ClickAndCollect);

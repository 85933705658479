import React, { useState } from 'react';

import {
  Typography,
  Paper,
  Grid,
  Box,
  Link,
  Snackbar,
  InputLabel,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  currencyFormat,
  getDate,
} from '../../utils';
import { useCookies } from 'react-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { useQuery } from 'react-query';
import { settlementSummaryApi } from 'src/services/settlements';
import CheckIcon from 'src/assets/svgIcons/CheckIcon';
import QuestionIcon from 'src/assets/svgIcons/QuestionIcon';
import { DropDown, Header } from 'src/components';
import { FormattedMessage } from 'react-intl';
import { startCase, uniqBy } from 'lodash';
import { DateRangePicker } from '../analytics/components';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    backgroundColor: '#f3f4f6',
  },
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#f3f4f6',
    padding: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    color: '#111928',
    marginBottom: 8,
  },
  retailerDropdown: {
    marginTop: '0 !important',
  },
  titleDropdown: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 8,
      marginTop: 8,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 8,
      marginRight: 8
    },
  },
  row: {
    '&:nth-child(2)': {
      backroundColor: '#f3f4f6',
    },
  },
  paper: {
    // minHeight: 280,
    width: 350,
    border: 10,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '90vw'
    },
  },
  rightItemsContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'flex-start',
      paddingBottom: 15,
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      paddingBottom: 15,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}));

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getCardStyle = (matched: any) => {
  let section = {
    marginTop: 16,
    padding: 8,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'space-between',
  };
  if (matched === true) {
    return {
      ...section,
      borderColor: '#CED4DA',
    };
  } else if (matched === false) {
    return {
      ...section,
      borderColor: '#CED4DA',
    };
  } else {
    return {
      ...section,
      borderColor: '#CED4DA',
    };
  }
};

const Settlements = (props: any) => {
  let { history } = props;
  let yesterday = new Date();
  const dispatch = useDispatch();
  yesterday.setDate(yesterday.getDate() - 1);
  const [filters, setFilters] = useState({});
  const [retailer, setRetailer] = useState('Retailer');
  const { active_stores } = useSelector((state: any) => state.store);
  const retailers = uniqBy(
    active_stores
      .map((item: any) => {
        if (item.store_type) {
          return {
            title: startCase(item.store_type.replace('StoreType', '')),
            value: item.store_type,
          };
        }
      })
      .filter((item: any) => item && item.value),
    'value',
  );

  const [toastVisible, showToast] = React.useState(false);

  let {
    settlements: { startDate, endDate, selectedRetailer = retailers[0] },
  } = useSelector((state: any) => state);

  const [cookies] = useCookies();
  const classes = useStyles();

  const navigateToDetailView = (type: any, psp: any = '', regionData: any) => {
    history.push({
      pathname: `/reportDetails/poslog`,
      state: { type, filters, regionData, startDate, endDate, psp },
    });
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason: any,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    showToast(false);
  };

  const appendCurrency = (currency: any, value: any) => {
    let number = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: currencyFormat(currency),
    }).format(value);
    return number;
    // return isNaN(Number(number))
    //   ? '--'
    //   : `${getCurrency(currency)} ${number
    //       .toString()
    //       .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const fetchSettlementSummary = async () => {
    try {
      let storeType = selectedRetailer.value;
      let params = { v1: true, token: cookies.app_token };
      let data = {
        store_type: storeType,
        start_date: getDate(startDate),
        end_date: getDate(endDate),
      };
      return settlementSummaryApi
        .create(params, data)
        .then((res: any) => {
          console.log({ res });
          let {
            csv_download_delimiter_formats = [],
            reports_region_wise = [],
          } = res.data;
          reports_region_wise.forEach((report: any) => {
            const poslogs = report.reports_sub.filter((report_sub: any) => {
              return report_sub.type === 'poslog_recon';
            });
            const paypouts = report.reports_sub.filter((report_sub: any) => {
              return report_sub.type === 'payout_recon';
            });
            report.reports_sub = [
              {
                header: 'Transactions',
                logs: poslogs,
                backgroundColor: '#fff',
              },
              { header: 'Payout', logs: paypouts, backgroundColor: '#FFF' },
            ];
          });
          let filters = reports_region_wise.map((item: any) => {
            return {
              region: item?.region,
              region_name: item.region_name,
              pspList: item?.payout_psp_payment_methods,
            };
          });
          dispatch({
            type: 'CHANGE_DELIMITER',
            payload: csv_download_delimiter_formats,
          });
          setFilters(filters);
          return res?.data;
        })
        .catch(e => {
          console.log(e);
        });
    } catch (e) {
      console.log({ e });
    }
  };

  const selectRetailerValue = (event: any) => {
    setRetailer(event.title);
    dispatch({
      type: 'CHANGE_SETTLEMENTS_SELECTED_RETAILER',
      payload: event,
    });
    return event.value;
  }

  const { isLoading, data, isFetching, error }: any = useQuery(
    [
      'analyticsData',
      'store_id',
      startDate,
      endDate,
      active_stores,
      selectedRetailer,
    ],
    fetchSettlementSummary,
    { refetchOnWindowFocus: false },
  );

  const renderRetailer = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          className={classes.titleDropdown}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <InputLabel className={classes.title} id="demo-simple-select-filled-label">Retailer</InputLabel>
          <DropDown
            classes={classes.retailerDropdown}
            title={retailer}
            dropList={retailers}
            selectHandler={selectRetailerValue}
          ></DropDown>
        </Box>
      </Box>
    );
  };

  const rightAccesory = () => (
    <Box className={classes.rightItemsContainer}>
      {renderRetailer()}
      <Box
        className={classes.titleDropdown}>
        <InputLabel
          id="demo-simple-select-label"
          className={classes.title}
          style={{
            marginBottom: 8,
            textAlign: 'left',
          }}
        >
          <FormattedMessage id="dateRange" defaultMessage="Date Range" />
        </InputLabel>
        <DateRangePicker
        handleDateChange={dateValue => {
          console.log('analytics date', dateValue);
          dispatch({
            type: 'CHANGE_START_DATE',
            payload: dateValue.startDate,
          });
          dispatch({
            type: 'CHANGE_END_DATE',
            payload: dateValue.endDate,
          });
        }}
        ></DateRangePicker>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        style={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Header
          title={
            <FormattedMessage
              id="settlementSummary"
              defaultMessage="Settlement Summary"
            />
          }
          showStores={true}
          rightAccessory={rightAccesory}
        />
        <Hidden only={['lg', 'xl']}>{rightAccesory()}</Hidden>
        {isLoading ? (
          <>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {data?.reports_region_wise?.length > 0 ? (
              <Grid
                container
                className={classes.container}
                spacing={2}
                // alignItems="center"
              >
                {data?.reports_region_wise?.map((item: any, index: number) => {
                  let {
                    region,
                    region_name,
                    currency,
                    reports_sub = [],
                  } = item;
                  let region_flag_url =
                    'https://static-assets.mishipay.com/country_flags/svg/' +
                    region.toLocaleLowerCase() +
                    '.svg';

                  return (
                    <Grid key={index} item>
                      <Box
                        style={{
                          maxHeight: '100%',
                          borderRadius: 4,
                          boxShadow:
                            '0px 1px 3px 0px rgba(166, 175, 195, 0.40)',
                        }}
                      >
                        <Paper className={classes.paper} variant="outlined">
                          {reports_sub?.length ? (
                            reports_sub.map(
                              (report_sub: any, index: number) => {
                                return report_sub?.logs?.length ? (
                                  <div
                                    key={index}
                                    style={{
                                      background: reports_sub.backgroundColor,
                                      padding: 16,
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: 'inline-flex',
                                        width: '100%',
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 'bold',
                                          flexDirection: 'row',
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        {report_sub.header}
                                      </Typography>
                                      <Box
                                        style={{
                                          display: 'inline-flex',
                                          marginRight: 0,
                                          marginLeft: 'auto',
                                        }}
                                      >
                                        <img
                                          src={region_flag_url}
                                          style={{
                                            height: 24,
                                            width: 24,
                                            paddingTop: 8,
                                          }}
                                        />
                                        <Typography
                                          variant="h6"
                                          style={{
                                            marginLeft: 8,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {region_name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    {report_sub?.logs?.map(
                                      (sub_report_item: any, index: number) => {
                                        let {
                                          type,
                                          psp,
                                          psp_display_name,
                                          link_title,
                                          matched,
                                          data,
                                        } = sub_report_item;
                                        let sectionStyle =
                                          getCardStyle(matched);
                                        let icon = () =>
                                          matched === true ? (
                                            <CheckIcon htmlColor={'#00e096'} />
                                          ) : matched === false ? (
                                            <QuestionIcon
                                              htmlColor={'#ffaa00'}
                                            />
                                          ) : (
                                            <></>
                                          );
                                        return (
                                          <Paper
                                            key={index}
                                            variant="outlined"
                                            style={{
                                              ...sectionStyle,
                                              background: '#FFFFFF',
                                            }}
                                          >
                                            {data.map(
                                              (items: any, index: number) => (
                                                <div
                                                  className={classes.row}
                                                  key={index}
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    fontSize: 14,
                                                    color: '#1F2A37',
                                                    fontWeight: 500,
                                                    justifyContent:
                                                      'space-between',
                                                    marginTop: 4,
                                                  }}
                                                >
                                                  <Typography
                                                    variant="subtitle2"
                                                    style={{
                                                      fontWeight: 'bold',
                                                      flex: 1,
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    {items.title}
                                                  </Typography>
                                                  <span
                                                    style={{
                                                      fontSize: 14,
                                                      fontWeight: 400,
                                                      color: '#1F2A37',
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                      flex: 1,
                                                    }}
                                                  >
                                                    <Typography variant="body2">
                                                      {appendCurrency(
                                                        currency,
                                                        items?.value,
                                                      )}
                                                    </Typography>
                                                  </span>
                                                </div>
                                              ),
                                            )}
                                            <div
                                              key={index}
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginTop: 4,
                                              }}
                                            >
                                              <u>
                                                <Link
                                                  component="button"
                                                  onClick={() => {
                                                    navigateToDetailView(
                                                      type,
                                                      psp,
                                                      {
                                                        region,
                                                        region_name,
                                                      },
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: 'pointer',
                                                    fontWeight: 500,
                                                    color: '#0074DF',
                                                    textAlign: 'left',
                                                  }}
                                                >
                                                  <Typography variant="subtitle2">
                                                    {link_title}
                                                  </Typography>
                                                </Link>
                                              </u>
                                              {icon()}
                                            </div>
                                          </Paper>
                                        );
                                      },
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                );
                              },
                            )
                          ) : (
                            <>
                              <Box style={{ padding: 16 }}>
                                <Typography variant="subtitle2">
                                  No Data Available
                                </Typography>
                              </Box>
                            </>
                          )}
                        </Paper>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  backgroundColor: '#f3f4f6',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  <FormattedMessage
                    id="noReports"
                    defaultMessage="No Reports Available"
                  />
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toastVisible}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          <FormattedMessage
            id="dateLimit"
            defaultMessage="Please select a date less than 60 days"
          />
        </Alert>
      </Snackbar>
    </>
  );
};

export default React.memo(Settlements);

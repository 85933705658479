import React, { useState } from 'react';
import { Box, Input, Typography, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import { Hidden } from '@material-ui/core';
import { goToOrderDetails } from 'src/utils';
import { useHistory } from 'react-router-dom';
import { SelectedStores } from '../../../../components/SelectedStores';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    minHeight: 90,
    display: 'flex',
    padding: 16,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32,
    },
  },
  container: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 60,
    },
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flex: 0.8,
    },
    flex: 1,
    alignItems: 'flex-start',
  },
  title: {
    fontSize: '24px',
    color: '#111928',
    fontWeight: 'bold',
    marginTop: 8,
    display: 'flex',
    alignItems: 'flex-start',
  },
  subtitle: {
    fontSize: '14px',
    color: '#637381',
  },
  subtitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  subtitle2: {
    color: '#637381',
    textAlign: 'left',
  },
  input: {
    width: 343,
    height: 40,
    display: 'flex',
    alignSelf: 'center',
  },
  inputContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    background: '#ffffff',
  },
}));

const Header = ({ title, subtitle, subtitle2 = '', rightAccessory }: any) => {
  const classes = useStyles();
  const { active_store } = useSelector((state: any) => state.store);
  const intl = useIntl();
  const history = useHistory();
  const [input, setInput] = useState('');
  const onChangeText = (e: any) => {
    setInput(e.target.value);
  };
  const onPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      const _input = input;
      setInput('');
      goToOrderDetails({
        o_id: _input,
        store_id: active_store.store_id,
        type: 'orders',
        from: 'orders',
        history,
      });
    }
  };

  const defaultRightAccessory = () => {
    return (
      <Input
        className={classes.input}
        fullWidth={true}
        name="search"
        onKeyDown={onPressEnter}
        placeholder={intl.formatMessage({ id: 'searchOrderId' })}
        onChange={onChangeText}
        autoComplete="off"
        value={input}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Typography className={classes.title}>{title}</Typography>
          <Box className={classes.subtitleContainer}>
            <SelectedStores
              source="header"
              title={
                <Typography className={classes.subtitle}>{subtitle}</Typography>
              }
            />
          </Box>
          <Typography variant="subtitle2" className={classes.subtitle2}>
            {subtitle2}
          </Typography>
        </Box>
        <Hidden only={['xs', 'sm', 'md']}>
          <Box className={classes.inputContainer}>
            {rightAccessory ? rightAccessory() : defaultRightAccessory()}
          </Box>
        </Hidden>
      </Box>
      <Hidden only={['lg', 'xl']}>
        {rightAccessory ? rightAccessory() : defaultRightAccessory()}
      </Hidden>
    </>
  );
};

export default Header;

import { SvgIcon } from '@material-ui/core';
import React from 'react';

const UploadSvgIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 14.25C18.875 14.25 18.5313 14.5625 18.5313 14.9687V17.75C18.5313 18.0312 18.3125 18.25 18.0312 18.25H2.96875C2.6875 18.25 2.46875 18.0312 2.46875 17.75V14.9687C2.46875 14.5625 2.125 14.25 1.75 14.25C1.375 14.25 1.03125 14.5625 1.03125 14.9687V17.75C1.03125 18.8125 1.875 19.6562 2.9375 19.6562H18.0312C19.0938 19.6562 19.9375 18.8125 19.9375 17.75V14.9687C19.9688 14.5625 19.625 14.25 19.25 14.25Z" fill="#111928" />
      <path d="M6.46875 6.96875L9.8125 3.71875V14.5313C9.8125 14.9063 10.125 15.25 10.5312 15.25C10.9062 15.25 11.25 14.9375 11.25 14.5313V3.71875L14.5937 6.96875C14.7187 7.09375 14.9062 7.15625 15.0938 7.15625C15.2812 7.15625 15.4688 7.09375 15.5938 6.9375C15.875 6.65625 15.8438 6.21875 15.5938 5.9375L11 1.5625C10.7187 1.3125 10.2812 1.3125 10.0312 1.5625L5.46875 5.96875C5.1875 6.25 5.1875 6.6875 5.46875 6.96875C5.75 7.21875 6.1875 7.25 6.46875 6.96875Z" fill="#111928" />
    </svg>
  );
};

export default UploadSvgIcon;

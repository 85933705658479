import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useReducer, useState } from 'react';
import { DatePicker, DateRangePicker } from '../../screens/analytics/components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import DropDown from '../Dropdown';
import clockIcon from '../../assets/clock.svg';
import coinIcon from '../../assets/coin.svg';

export type DateTimeFilterValues = {
  fromDate?: Date | null;
  toDate?: Date | null;
  fromTime?: string;
  toTime?: string;
  fromPrice?: string;
  toPrice?: string;
  isApplied?: boolean;
  order_type?: string;
  retailerDeviceId?: string;
};

type DateTimeFilterObj = DateTimeFilterValues & {
  closeFiltersHandler: () => void;
  applyFiltersHandler: (filters: DateTimeFilterValues) => {};
};

const timeBrackets = [
  { title: '00:00', value: '00:00' },
  { title: '01:00', value: '01:00' },
  { title: '02:00', value: '02:00' },
  { title: '03:00', value: '03:00' },
  { title: '04:00', value: '04:00' },
  { title: '05:00', value: '05:00' },
  { title: '06:00', value: '06:00' },
  { title: '07:00', value: '07:00' },
  { title: '08:00', value: '08:00' },
  { title: '09:00', value: '09:00' },
  { title: '10:00', value: '10:00' },
  { title: '11:00', value: '11:00' },
  { title: '12:00', value: '12:00' },
  { title: '13:00', value: '13:00' },
  { title: '14:00', value: '14:00' },
  { title: '15:00', value: '15:00' },
  { title: '16:00', value: '16:00' },
  { title: '17:00', value: '17:00' },
  { title: '18:00', value: '18:00' },
  { title: '19:00', value: '19:00' },
  { title: '20:00', value: '20:00' },
  { title: '21:00', value: '21:00' },
  { title: '22:00', value: '22:00' },
  { title: '23:00', value: '23:00' },
];

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      '& .MuiDialog-paper': {
        margin: 10,
        height: '75%',
      },
      '& .MuiDialog-paperFullWidth': {
        width: '100%',
        maxWidth: 700,
      }
    },
    dialogContent: {
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: 8,
      }
    },
    selectIcon: {
      marginRight: 15,
      color: '#231F20',
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.color_black.main,
    },
    containerClass: {
      width: '43%',
      '& div': {
        width: '100%',
      },
    },
    dateRangePicker: {
      width: '100% !important',
    },
    timeInput: {
      marginTop: '0 !important',
      '& button': {
        width: '100%',
      },
      '& span': {
        width: '100%',
        justifyItems: 'right',
      },
      '& #drowpdownContainer': {
        height: '30vh',
      }
    },
    textField: {
      border: `1px solid ${theme.palette.border_input_color.main}`,
      borderRadius: 6,
      '& .MuiInput-root': {
        backgroundColor: '#fff !important',
      },
    },
    applyBtn: {
      '&:disabled': {
        background: '#CCCCCC !important',
        color: '#ffffff',
      },
    },
    staticFooter: {
      position: 'sticky',
      bottom: 0,
      background: '#fff',
      paddingTop: 0,
    },
    actionContainer: {
      width: '100%',
      textAlign: 'end',
      [theme.breakpoints.down('xs')]: {
        display: 'inline-flex',
      }
    },
    clear: {
      [theme.breakpoints.down('xs')]: {
        float: 'left',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 !important',
        width: 'auto !important',
      }
    },
    clearButton: {
      display: 'inline-flex',
      width: '100%',
      margin: '0 20px',
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        display: 'block',
        width: '100%',
      }
    },
    cancelButton: {
      marginRight: 8,
      color: '#000',
      textTransform: 'none',
      borderRadius: 6,
      border: '1px solid #111928',
      width: 100,
      [theme.breakpoints.down('xs')]: {
        width: '50%'
      },
    },
    selectedStoresButton: {
      textTransform: 'none',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
    },
  }),
);

const reducer = (
  state: DateTimeFilterValues,
  action: { type: string; data?: any },
) => {
  let newState = {
    fromDate: state.fromDate,
    toDate: state.toDate,
    fromTime: state.fromTime,
    toTime: state.toTime,
    fromPrice: state.fromPrice,
    toPrice: state.toPrice,
    retailerDeviceId: state.retailerDeviceId,
    isApplied: true,
  };
  switch (action.type) {
    case 'FROM_DATE':
      newState.fromDate = action.data;
      break;
    case 'TO_DATE':
      newState.toDate = action.data;
      break;
    case 'FROM_TIME':
      newState.fromTime = action.data;
      break;
    case 'TO_TIME':
      newState.toTime = action.data;
      break;
    case 'FROM_PRICE':
      newState.fromPrice = action.data;
      break;
    case 'TO_PRICE':
      newState.toPrice = action.data;
      break;
    case 'RETAILER_DEVICE_ID':
      newState.retailerDeviceId = action.data;
      break;
    case 'RESET':
      newState = initialState;
      break;
  }
  return newState;
};

const initialState = {
  fromDate: null,
  toDate: null,
  fromTime: '00:00',
  toTime: '23:00',
  fromPrice: '',
  toPrice: '',
  retailerDeviceId: '',
  isApplied: false,
};

const DateTimeFilter = (props: DateTimeFilterObj) => {
  const classes = useStyles();
  const [dateTitle, setdateTitle] = useState('Date Range');
  const {
    closeFiltersHandler,
    applyFiltersHandler,
    fromDate,
    toDate,
    fromTime,
    toTime,
    fromPrice,
    toPrice,
    isApplied,
    retailerDeviceId,
  } = props;
  const [filtersData, dispatch] = useReducer(
    reducer,
    isApplied
      ? {
        fromDate,
        toDate,
        fromTime,
        toTime,
        fromPrice,
        toPrice,
        isApplied,
        retailerDeviceId,
      }
      : initialState,
  );

  const selectTimeHandler = (data: any) => {
    console.log(data);
    setdateTitle(data.title);
    dispatch({
      type: 'FROM_TIME',
      data: data.value,
    });
    dispatch({
      type: 'TO_TIME',
      data: data.value,
    });
    return data.value;
  };

  return (
    <Dialog open={true} fullWidth={true} classes={{ root: classes.root }}>
      <DialogContent className={classes.dialogContent}>
        <Box
          style={{
            alignSelf: 'center',
            marginTop: 10,
          }}
        >
          <div>
            <Typography
              className={classes.title}
              style={{ marginBottom: 10 }}
            >
              Date Range
            </Typography>
            <DateRangePicker
              label={dateTitle}
              classes={classes.dateRangePicker}
              handleDateChange={dateValue => {
                console.log('analytics date', dateValue);
                setdateTitle(undefined);
                dispatch({
                  type: 'FROM_DATE',
                  data: dateValue.startDate,
                });
                dispatch({
                  type: 'TO_DATE',
                  data: dateValue.endDate,
                });

              }}
            ></DateRangePicker>
          </div>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 10,
          }}
        >
          <Typography
            className={classes.title}
          >
            Time Range
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <DropDown
            icon={<img src={clockIcon} alt="Calendar Icon" className="w-[20px] h-[20px] max-w-none" />}
            disabled={!filtersData.fromDate}
            title={filtersData.fromTime}
            dropList={timeBrackets}
            classes={classes.timeInput}
            containerClasses={classes.containerClass}
            selectHandler={(data) => {
              dispatch({
                type: 'FROM_TIME',
                data: data.value,
              }); return data.value;
            }}
          ></DropDown>
          <span
            style={{
              color: '#8F9BB3',
              fontWeight: 'bold',
            }}
          >
            -
          </span>
          <DropDown
            icon={<img src={clockIcon} alt="Calendar Icon" className="w-[20px] h-[20px] max-w-none" />}
            disabled={!filtersData.toDate}
            title={filtersData.toTime}
            dropList={timeBrackets}
            classes={classes.timeInput}
            containerClasses={classes.containerClass}
            selectHandler={(data) => {
              dispatch({
                type: 'TO_TIME',
                data: data.value,
              }); return data.value;
            }}
          ></DropDown>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 10,
          }}
        >
          <Typography
            className={classes.title}
          >
            Filter by amount
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div style={{ position: 'relative', flex: 1 / 2.3 }}>
            <img
              src={coinIcon}
              alt="Coin Icon"
              style={{
                position: 'absolute',
                top: '50%',
                left: 10,
                transform: 'translateY(-50%)',
                width: 20,
                height: 20,
                pointerEvents: 'none',
                marginLeft: 6,
              }}
            />
            <TextField
              className={classes.textField}
              value={filtersData.fromPrice}
              type="number"
              placeholder="Amount above"
              variant="standard"
              style={{ paddingLeft: 40, width: '100%', }} 
              onChange={({ target: { value } }) => {
                if (/^\d+(\.\d{0,2})?$/.test(value)) {
                  dispatch({ type: 'FROM_PRICE', data: value });
                } else if (!value) {
                  dispatch({ type: 'FROM_PRICE', data: '' });
                } else if (value.startsWith('-')) {
                  dispatch({ type: 'FROM_PRICE', data: value.replace(/-/g, '') });
                }
              }}
              onBlur={event => {
                if (
                  filtersData.toPrice &&
                  +event.target.value > +filtersData.toPrice
                ) {
                  dispatch({ type: 'FROM_PRICE', data: filtersData.toPrice });
                }
                if (!filtersData.fromPrice) {
                  event.target.value = '';
                }
              }}
            />
          </div>
          <span
            style={{
              color: '#8F9BB3',
              fontWeight: 'bold',
            }}
          >
            -
          </span>
          <div style={{ position: 'relative', flex: 1 / 2.3 }}>
            <img
              src={coinIcon}
              alt="Coin Icon"
              style={{
                position: 'absolute',
                top: '50%',
                left: 10,
                transform: 'translateY(-50%)',
                width: 20,
                height: 20,
                pointerEvents: 'none',
                marginLeft: 6,
              }}
            />
            <TextField
              className={classes.textField}
              value={filtersData.toPrice}
              type="number"
              placeholder="Amount below"
              variant="standard"
              style={{ flex: 1 / 2.3, paddingLeft: 40, width: '100%', }}
              onChange={({ target: { value } }) => {
                if (/^\d+(\.\d{0,2})?$/.test(value)) {
                  dispatch({ type: 'TO_PRICE', data: value });
                } else if (!value) {
                  dispatch({ type: 'TO_PRICE', data: '' });
                } else if (value.startsWith('-')) {
                  dispatch({ type: 'TO_PRICE', data: value.replace(/-/g, '') });
                }
              }}
              onBlur={event => {
                if (
                  filtersData.fromPrice &&
                  +event.target.value < +filtersData.fromPrice
                ) {
                  dispatch({ type: 'TO_PRICE', data: filtersData.fromPrice });
                }
                if (!filtersData.toPrice) {
                  event.target.value = '';
                }
              }}
            />
          </div>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 10,
          }}
        >
          <Typography
            className={classes.title}
          >
            Retailer Device Id
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <TextField
            className={classes.textField}
            value={filtersData.retailerDeviceId}
            type="string"
            placeholder="Enter Retailer device id"
            variant="standard"
            style={{ flex: 1 / 2.3 }}
            onChange={({ target: { value } }) => {
              if (!value) {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: '' });
              } else {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: value });
              }
            }}
            onBlur={event => {
              if (!event.target.value) {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: '' });
              } else {
                dispatch({
                  type: 'RETAILER_DEVICE_ID',
                  data: event.target.value,
                });
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.staticFooter}>
        <Box className={classes.clearButton}
        >
          <Button
            onClick={() => { setdateTitle('Date Range'); dispatch({ type: 'RESET' }) }}
            color={'secondary'}
            className={classes.clear}
            style={{ textTransform: 'none', width: 100 }}
          >
            Clear All
          </Button>
          <Box className={classes.actionContainer}>
            <Button
              onClick={closeFiltersHandler}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              disabled={
                filtersData.fromDate || filtersData.toDate
                  ? !(
                    filtersData.fromDate &&
                    filtersData.toDate &&
                    ((filtersData.fromPrice && filtersData.toPrice) ||
                      (!filtersData.fromPrice && !filtersData.toPrice))
                  )
                  : filtersData.fromPrice || filtersData.toPrice
                    ? !(filtersData.fromPrice && filtersData.toPrice)
                    : !isApplied
              }
              onClick={() => {
                applyFiltersHandler(filtersData);
                closeFiltersHandler();
              }}
              color="primary"
              variant="contained"
              className={classes.selectedStoresButton}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default DateTimeFilter;

import React, { useEffect, useRef, useState } from 'react';

export type DropdownValues = {
  title?: string | null;
  titleKey?: string | null;
  dropList?: any | null;
  disabled?: boolean | null;
  classes?: any | null;
  containerClasses?: any | null;
  icon?: React.ReactNode;
};
type DropdownObj = DropdownValues & {
  closeHandler?: () => void;
  selectHandler: (value) => {};
};

let useClickOutside = handler => {
  let domNode = useRef<HTMLInputElement>();

  useEffect(() => {
    let maybeHandler = event => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return domNode;
};

const DropDown = (props: DropdownObj) => {
  const { title, titleKey, disabled, classes, containerClasses, closeHandler, selectHandler, icon } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let domNode = useClickOutside(() => {
    setDropdownOpen(false);
  });

  return (
    <>
      <div ref={domNode} className={`${containerClasses}`}>
        <div className={`relative inline-block mt-[6px] text-left ${classes}`}>
          <button
            disabled={disabled}
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className={`bg-white capitalize flex items-center border-[#D1D5DB] border-solid border-[1px] rounded-[6px] px-[16px] py-[12px] text-base font-medium text-[#111928]`}
          >
            {icon && <div className="w-[30px] mr-2">{icon}</div>}
            {props.title}
            <span className="pl-4">
              <svg
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current"
              >
                <path d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4063 5.65625 17.6875 5.9375C17.9687 6.21875 17.9687 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1563 10.1875 14.25 10 14.25Z" />
              </svg>
            </span>
          </button>
          <div id="drowpdownContainer"
            className={`shadow-1 absolute left-0 z-40 mt-2 w-full rounded-md bg-white py-[10px] transition-all ${
              dropdownOpen
                ? 'top-full opacity-100 visible max-h-[75vh] overflow-y-auto'
                : 'top-[110%] invisible opacity-0'
            }`}
          >
            {props.dropList.map((item: any, index: number) => (
              <div
                id={index.toString()}
                onClick={() => {
                  selectHandler(item);
                  setDropdownOpen(!dropdownOpen);
                }}
                className="text-[#111928] hover:bg-primary/5 hover:text-primary block py-[12px] px-[16px] text-base text-left"
              >
                {titleKey ? item[titleKey] : item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDown;

import { SvgIcon } from '@material-ui/core';
import React from 'react';

const DownloadSvgIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.25 14.25C18.875 14.25 18.5313 14.5625 18.5313 14.9687V17.75C18.5313 18.0313 18.3125 18.25 18.0312 18.25H2.96875C2.6875 18.25 2.46875 18.0313 2.46875 17.75V14.9687C2.46875 14.5625 2.125 14.25 1.75 14.25C1.375 14.25 1.03125 14.5625 1.03125 14.9687V17.75C1.03125 18.8125 1.875 19.6563 2.9375 19.6563H18.0312C19.0938 19.6563 19.9375 18.8125 19.9375 17.75V14.9687C19.9688 14.5625 19.625 14.25 19.25 14.25Z" fill="white" />
      <path d="M9.99993 15.0313C10.1249 15.1563 10.3124 15.2188 10.4999 15.2188C10.6874 15.2188 10.8437 15.1563 10.9999 15.0313L15.5312 10.625C15.8124 10.3438 15.8124 9.90625 15.5312 9.625C15.2499 9.34375 14.8124 9.34375 14.5312 9.625L11.2187 12.875V2.0625C11.2187 1.6875 10.9062 1.34375 10.4999 1.34375C10.1249 1.34375 9.78118 1.65625 9.78118 2.0625V12.875L6.46868 9.625C6.18743 9.34375 5.74993 9.375 5.46868 9.625C5.18743 9.90625 5.21868 10.3438 5.46868 10.625L9.99993 15.0313Z" fill="white" />
    </svg>
  );
};

export default DownloadSvgIcon;

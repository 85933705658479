import React, { useState } from 'react';
import { Paper, Hidden, Box, Input, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { goToOrderDetails } from 'src/utils';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import QrReader from 'react-qr-reader';
import { InputAndPopper } from 'src/components';

const useStyles = makeStyles((theme: any) => ({
  root: {
    with: '100vw',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.between('sm', 'md')]: {
      backgroundColor: '#fff',
      paddingBottom: 5,
    },
  },

  bodyContainer: {
    height: '100%',
    margin: '30px',
    marginTop: 15,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${136}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${170}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    overflow: 'hidden',
    [theme.breakpoints.between('sm','md')]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 20,
      justifyContent: 'flex-start',
      background: '#efefef',
    },
  },

  body: {
    width: '95%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: '20px 0',
      background: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  infoText: {
    color: '#111928',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'left',
    width: '95%',
    marginBottom: 25,
  },
  scanner: {
    [theme.breakpoints.up('xl')]: {
      width: '40% !important',
      height: '40% !important',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '45% !important',
      height: '45% !important',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85% !important',
      height: '85% !important',
    },
    width: '50% !important',
    height: '50% !important',
    display: 'table',
  },
}));

/**
 * GPP-5582
 * if the scanned value of is a URL pattern(ref-GPP-5271),
 * then extract the query paramater 'order_id' and return the value
 * @param scannerResult
 */
const extractScanResult = (scannerResult: string) => {
  const isMishiPayURLPattern =
    /^https\:\/\/(dev|test|app)\.mishipay\.com\/order-management\/v2\/order-receipt\/\?order_id\=([a-zA-z0-9-]{1,})\&entity_type\=user\&receipt_id\=([0-9]{1,})$/;

  if (isMishiPayURLPattern.test(scannerResult)) {
    try {
      const mishiPayURL = new URL(scannerResult);
      scannerResult = mishiPayURL.searchParams.get('order_id') || '';
    } catch (e) {
      console.debug(`failed to parse URL :${scannerResult}`);
    }
  }
  return scannerResult;
};

const Scan = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.root}>
      <Header
        showStores={true}
        title={<FormattedMessage id="scanOrder" defaultMessage="Scan" />}
      />
      <Hidden only={['lg', 'xl']}>
        <Box className={classes.input}>
          <InputAndPopper />
        </Box>
      </Hidden>
      <Box component="div" className={classes.bodyContainer}>
        <Box className={classes.infoText}>
          <FormattedMessage
            id="scanRecipt"
            defaultMessage="Scan customer receipt QR at"
          />
        </Box>
        <Box component="div" className={classes.body}>
          <QrReader
            className={classes.scanner}
            delay={300}
            onError={(err: any) => {
              // console.log(err);
            }}
            onScan={(scan: any) => {
              console.debug(`raw scanner output :${scan}`);
              scan = extractScanResult(scan);
              console.debug(`parsed scanner output :${scan}`);
              if (scan) {
                goToOrderDetails({
                  order_id: scan,
                  type: 'orders',
                  from: 'orders',
                  history,
                });
              }
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default Scan;

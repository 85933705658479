

const BarcodeShow = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.625 0.519531H1.1875C0.8125 0.519531 0.46875 0.832031 0.46875 1.23828V4.67578C0.46875 5.05078 0.78125 5.39453 1.1875 5.39453C1.59375 5.39453 1.90625 5.08203 1.90625 4.67578V1.92578H4.625C5 1.92578 5.34375 1.61328 5.34375 1.20703C5.34375 0.800781 5 0.519531 4.625 0.519531Z" fill="#3758F9" />
      <path d="M16.8125 10.8008C16.4375 10.8008 16.0938 11.1133 16.0938 11.5195V14.2695H13.375C13 14.2695 12.6562 14.582 12.6562 14.9883C12.6562 15.3945 12.9687 15.707 13.375 15.707H16.8125C17.1875 15.707 17.5312 15.3945 17.5312 14.9883V11.5195C17.5312 11.1133 17.1875 10.8008 16.8125 10.8008Z" fill="#3758F9" />
      <path d="M16.8125 0.519531H13.375C13 0.519531 12.6562 0.832031 12.6562 1.23828C12.6562 1.64453 12.9687 1.95703 13.375 1.95703H16.125V4.64453C16.125 5.01953 16.4375 5.36328 16.8437 5.36328C17.25 5.36328 17.5625 5.05078 17.5625 4.64453V1.20703C17.5312 0.832031 17.1875 0.519531 16.8125 0.519531Z" fill="#3758F9" />
      <path d="M4.625 14.2383H1.90625V11.5195C1.90625 11.1445 1.59375 10.8008 1.1875 10.8008C0.78125 10.8008 0.46875 11.1133 0.46875 11.5195V14.957C0.46875 15.332 0.78125 15.6758 1.1875 15.6758H4.625C5 15.6758 5.34375 15.3633 5.34375 14.957C5.34375 14.5508 5 14.2383 4.625 14.2383Z" fill="#3758F9" />
      <path d="M6.53125 11.6133C6.15625 11.6133 5.8125 11.9258 5.8125 12.332V12.8945C5.8125 13.2695 6.125 13.6133 6.53125 13.6133C6.9375 13.6133 7.25 13.3008 7.25 12.8945V12.332C7.21875 11.9258 6.90625 11.6133 6.53125 11.6133Z" fill="#3758F9" />
      <path d="M9.28125 2.58203C8.90625 2.58203 8.5625 2.89453 8.5625 3.30078V12.8945C8.5625 13.2695 8.875 13.6133 9.28125 13.6133C9.6875 13.6133 9.96875 13.3008 9.96875 12.8945V3.30078C10 2.89453 9.6875 2.58203 9.28125 2.58203Z" fill="#3758F9" />
      <path d="M15.5 12.8633V3.30078C15.5 2.92578 15.1875 2.58203 14.7812 2.58203C14.375 2.58203 14.0625 2.89453 14.0625 3.30078V12.8945C14.0625 13.2695 14.375 13.6133 14.7812 13.6133C15.1875 13.6133 15.5 13.2695 15.5 12.8633Z" fill="#3758F9" />
      <path d="M4.46875 12.8633V3.30078C4.46875 2.92578 4.15625 2.58203 3.75 2.58203C3.34375 2.58203 3.03125 2.89453 3.03125 3.30078V12.8945C3.03125 13.2695 3.34375 13.6133 3.75 13.6133C4.15625 13.6133 4.46875 13.2695 4.46875 12.8633Z" fill="#3758F9" />
      <path d="M6.53125 2.58203C6.15625 2.58203 5.8125 2.89453 5.8125 3.30078V10.4258C5.8125 10.8008 6.125 11.1445 6.53125 11.1445C6.9375 11.1445 7.25 10.832 7.25 10.4258V3.30078C7.21875 2.89453 6.90625 2.58203 6.53125 2.58203Z" fill="#3758F9" />
      <path d="M12.75 12.3008C12.75 11.9258 12.4375 11.582 12.0312 11.582C11.625 11.582 11.3125 11.8945 11.3125 12.3008V12.8633C11.3125 13.2383 11.625 13.582 12.0312 13.582C12.4375 13.582 12.75 13.2695 12.75 12.8633V12.3008Z" fill="#3758F9" />
      <path d="M12.0625 11.1133C12.4375 11.1133 12.7812 10.8008 12.7812 10.3945V3.30078C12.7812 2.92578 12.4688 2.58203 12.0625 2.58203C11.6562 2.58203 11.3438 2.89453 11.3438 3.30078V10.4258C11.3438 10.8008 11.6562 11.1133 12.0625 11.1133Z" fill="#3758F9" />
    </svg>
  );
};

export default BarcodeShow;

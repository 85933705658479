import React, { memo } from 'react';
import {
  Card,
  Box,
  Typography,
  Button,
  Divider,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { verificationReasons } from 'src/utils';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import AlertIcon from 'src/assets/svgIcons/AlertIcon';
import ArrowRight from 'src/assets/svgIcons/ArrowRight';
import ApproveIcon from 'src/assets/svgIcons/ApproveIcon';
import RemoveIcon from 'src/assets/svgIcons/RemoveIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '680px',
    },
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      margin: '15px',
    },
    border: 0,
    borderRadius: 4,
    background: 'var(--White-White, #FFF)',
    boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 15,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
  },
  order: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 6,
  },
  date: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.color_light_grey.main,
    alignSelf: 'flex-start',
  },
  storeName: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
    textAlign: 'left',
  },
  orderDateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bodyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 30,
  },
  item1: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  item2: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },

  issue: {
    flex: 1,
    fontSize: '16px',
    margin: 'auto',
    textTransform: 'none',
    fontWeight: 500,
    alignSelf: 'center',
    padding: 10,
    display: 'flex',
    width: '100%',
    color: '#854904',
    borderRadius: '4px',
    border: '1px solid var(--Yellow-Yellow, #FBBF24)',
    background: 'var(--Yellow-Yellow-light-4, #FFFBEB)',
    [theme.breakpoints.down('sm')]: {
      height: 'auto !important',
    },
  },

  buttonLeft: {
    fontSize: '16px',
    width: '200px',
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.error.main}`,
    height: '50px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: `${theme.palette.error.main}1a`,
    },
  },

  buttonRight: {
    fontSize: '16px',
    width: '200px',
    textTransform: 'capitalize',
    backgroundColor: 'transparent',
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    marginLeft: 16,
    height: '50px',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: `${theme.palette.success.main}1a`,
    },
  },

  buttonLeftRipple: {
    backgroundColor: `${theme.palette.error.main}1A`,
  },
  buttonRightRipple: {
    color: `${theme.palette.success.main}`,
  },

  footer: {
    display: 'flex',
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'left',
  },
}));

const itemList = [
  {
    title: <FormattedMessage id="total" defaultMessage="Total" />,
  },
  {
    title: <FormattedMessage id="items" defaultMessage="Items" />,
  },
  {
    title: <FormattedMessage id="customer" defaultMessage="Customer" />,
  },
];

const VerificationCard = ({ item, showAlert, setModalData }: any) => {
  const { code, date, reason, store_name, tags, id, items } = item;

  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const theme: any = useTheme();

  const itemsLength = items.length;

  const navigateToDetails = () => {
    history.push({
      pathname: `/verificationDetails/`,
      state: {
        items: JSON.stringify(item),
      },
    });
  };
  return (
    <Card className={classes.root} variant="outlined">
      <Box className={classes.header} onClick={navigateToDetails}>
        <Box className={classes.orderDateTimeContainer}>
          <Typography className={classes.order}>{`${intl.formatMessage({
            id: 'request',
          })} #${code}`}</Typography>
          <Box
            style={{
              display: 'flex',
            }}
          >
            <Typography className={classes.date}>
              {moment(date).format('DD/MM/YY HH:mm')} ( UTC{' '}
              {moment(date).utc().format('HH:mm')} ){' '}
            </Typography>
          </Box>
          <Typography className={classes.storeName}>{store_name}</Typography>
        </Box>
        <Hidden only={['xs', 'sm']}>
          <Box className="h-full w-[50%]">
            <Button className={classes.issue} onClick={navigateToDetails}>
              {verificationReasons(reason, tags, intl)}
            </Button>
          </Box>
        </Hidden>
      </Box>
      <Hidden only={['md', 'lg', 'xl']}>
        <Box className="h-full w-[90%] m-auto">
          <Button className={classes.issue} onClick={navigateToDetails}>
            {verificationReasons(reason, tags, intl)}
          </Button>
        </Box>
      </Hidden>
      <Divider light className="my-[15px] bg-[#DFE4EA]" />
      <Box
        className={classes.footer}
        borderColor={theme.palette.color_light_grey.main}
      >
        <Button
          startIcon={<RemoveIcon />}
          className={classes.buttonLeft}
          variant="outlined"
          onClick={() => {
            showAlert(true);
            setModalData({
              modalText: intl.formatMessage({ id: 'confirmRejectCheck' }),
              action: 'reject',
              sessionId: id,
            });
          }}
        >
          <FormattedMessage
            id="reject"
            defaultMessage="REJECT"
          ></FormattedMessage>
        </Button>
        <Button
          startIcon={<ApproveIcon />}
          className={classes.buttonRight}
          variant="contained"
          TouchRippleProps={{
            classes: { root: classes.buttonRightRipple },
          }}
          onClick={() => {
            setModalData({
              modalText: intl.formatMessage({ id: 'confirmApproveCheck' }),
              action: 'accept',
              sessionId: id,
            });
            showAlert(true);
          }}
        >
          <FormattedMessage
            id="accept"
            defaultMessage="Approve"
          ></FormattedMessage>
        </Button>
        <Hidden only={['xs', 'sm']}>
          <Box className="inline-flex text-[#637381] text-[16px] font-[500] !mr-0 ml-[10px] my-auto h-[16px] w-[100%] justify-end" onClick={navigateToDetails}>
            <FormattedMessage
              id="view"
              defaultMessage="View"
            ></FormattedMessage>
            <Box className="ml-[5px] h-[16px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                style={{ height: '14px', marginTop: '2px', marginLeft: '5px' }}
              >
                <path
                  d="M1.20445 15.2865L1.20447 15.2865L1.20721 15.2837L7.76971 8.56494L7.99773 8.33149L7.76916 8.09856L1.20667 1.41106L1.20668 1.41105L1.20445 1.40883C1.05338 1.25775 1.05338 1.03131 1.20445 0.880233C1.35523 0.729454 1.58109 0.729159 1.73216 0.879348C1.73246 0.879643 1.73275 0.879938 1.73305 0.880233L8.79349 8.06566L8.79348 8.06567L8.79555 8.06773C8.94662 8.21881 8.94662 8.44525 8.79555 8.59633L8.79349 8.5984L1.73305 15.7838C1.73276 15.7841 1.73248 15.7844 1.73219 15.7847C1.65773 15.8587 1.55187 15.9049 1.46875 15.9049C1.37385 15.9049 1.29256 15.8791 1.19249 15.8026C1.05349 15.6518 1.05747 15.4335 1.20445 15.2865Z"
                  fill="#637381"
                  stroke="#637381"
                  stroke-width="0.666667"
                />
              </svg>
            </Box>
          </Box>
        </Hidden>
      </Box>
      <Hidden only={['md', 'lg', 'xl']}>
        <Box style={{ textAlign: 'right', padding: '10px 16px 0' }}>
          <Box className="inline-flex text-[#637381] text-[16px] font-[500] !mr-0 ml-auto my-auto h-[16px]" onClick={navigateToDetails}>
            <FormattedMessage
              id="view"
              defaultMessage="View"
            ></FormattedMessage>
            <Box className="ml-[5px] h-[16px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                style={{ height: '14px', marginTop: '2px', marginLeft: '5px' }}
              >
                <path
                  d="M1.20445 15.2865L1.20447 15.2865L1.20721 15.2837L7.76971 8.56494L7.99773 8.33149L7.76916 8.09856L1.20667 1.41106L1.20668 1.41105L1.20445 1.40883C1.05338 1.25775 1.05338 1.03131 1.20445 0.880233C1.35523 0.729454 1.58109 0.729159 1.73216 0.879348C1.73246 0.879643 1.73275 0.879938 1.73305 0.880233L8.79349 8.06566L8.79348 8.06567L8.79555 8.06773C8.94662 8.21881 8.94662 8.44525 8.79555 8.59633L8.79349 8.5984L1.73305 15.7838C1.73276 15.7841 1.73248 15.7844 1.73219 15.7847C1.65773 15.8587 1.55187 15.9049 1.46875 15.9049C1.37385 15.9049 1.29256 15.8791 1.19249 15.8026C1.05349 15.6518 1.05747 15.4335 1.20445 15.2865Z"
                  fill="#637381"
                  stroke="#637381"
                  stroke-width="0.666667"
                />
              </svg>
            </Box>
          </Box>
        </Box>
      </Hidden>
    </Card>
  );
};

export default memo(VerificationCard);

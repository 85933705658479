import { SvgIcon } from '@material-ui/core';
import React from 'react';

const VerifySvgCompontIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1457_6034)">
        <path d="M10.5 0.644531C5.28125 0.644531 1.0625 4.86328 1.0625 10.082C1.0625 15.3008 5.28125 19.5508 10.5 19.5508C15.7188 19.5508 19.9688 15.3008 19.9688 10.082C19.9688 4.86328 15.7188 0.644531 10.5 0.644531ZM10.5 18.1445C6.0625 18.1445 2.46875 14.5195 2.46875 10.082C2.46875 5.64453 6.0625 2.05078 10.5 2.05078C14.9375 2.05078 18.5625 5.67578 18.5625 10.1133C18.5625 14.5195 14.9375 18.1445 10.5 18.1445Z" fill="white" />
        <path d="M13.1875 7.17595L9.46874 10.801L7.78124 9.1447C7.49999 8.86345 7.06249 8.8947 6.78124 9.1447C6.49999 9.42595 6.53124 9.86345 6.78124 10.1447L8.78124 12.0822C8.96874 12.2697 9.21874 12.3635 9.46874 12.3635C9.71874 12.3635 9.96874 12.2697 10.1562 12.0822L14.1875 8.2072C14.4687 7.92595 14.4687 7.48845 14.1875 7.2072C13.9062 6.92595 13.4687 6.92595 13.1875 7.17595Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1457_6034">
          <rect width="20" height="20" fill="white" transform="translate(0.5 0.0820312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifySvgCompontIcon;

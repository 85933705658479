import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ReceiptSvgCompontIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5937 3.21875C17.5937 2.03125 16.625 1.03125 15.4062 1.03125H4.59375C3.40625 1.03125 2.40625 2 2.40625 3.21875V18.9062C2.40625 19.1562 2.5625 19.4062 2.78125 19.5312C3 19.6562 3.28125 19.625 3.5 19.5L5.0625 18.4687L7.15625 19.8437C7.40625 20 7.6875 20 7.9375 19.8437L10 18.4687L12.0625 19.8437C12.1875 19.9375 12.3125 19.9687 12.4375 19.9687C12.5625 19.9687 12.7187 19.9375 12.8125 19.8437L14.875 18.4687L16.4375 19.5312C16.6562 19.6875 16.9375 19.6875 17.1562 19.5625C17.375 19.4375 17.5312 19.1875 17.5312 18.9375L17.5937 3.21875ZM15.5937 17.2187C15.1875 16.9375 14.6562 16.9375 14.25 17.2187L12.4687 18.4062L10.6875 17.2187C10.4687 17.0937 10.25 17 10 17C9.78125 17 9.53125 17.0625 9.34375 17.2187L7.5625 18.4062L5.78125 17.2187C5.375 16.9375 4.84375 16.9375 4.4375 17.2187L3.84375 17.5937V3.21875C3.84375 2.78125 4.1875 2.4375 4.625 2.4375H15.4687C15.9062 2.4375 16.25 2.78125 16.25 3.21875V17.5937L15.5937 17.2187Z" fill="#3758F9" />
      <path d="M8.8125 4.65625H6.125C5.75 4.65625 5.4375 4.96875 5.4375 5.34375C5.4375 5.71875 5.75 6.03125 6.125 6.03125H8.8125C9.1875 6.03125 9.5 5.71875 9.5 5.34375C9.5 4.96875 9.1875 4.65625 8.8125 4.65625Z" fill="#3758F9" />
      <path d="M13.7188 4.65625H12.9688C12.5938 4.65625 12.2812 4.96875 12.2812 5.34375C12.2812 5.71875 12.5938 6.03125 12.9688 6.03125H13.7188C14.0938 6.03125 14.4062 5.71875 14.4062 5.34375C14.4062 4.96875 14.0938 4.65625 13.7188 4.65625Z" fill="#3758F9" />
      <path d="M6.125 10.1562H7.84375C8.21875 10.1562 8.53125 9.84375 8.53125 9.46875C8.53125 9.09375 8.21875 8.78125 7.84375 8.78125H6.125C5.75 8.78125 5.4375 9.09375 5.4375 9.46875C5.4375 9.84375 5.71875 10.1562 6.125 10.1562Z" fill="#3758F9" />
      <path d="M13.7188 8.75H12.9688C12.5938 8.75 12.2812 9.0625 12.2812 9.4375C12.2812 9.8125 12.5938 10.125 12.9688 10.125H13.7188C14.0938 10.125 14.4062 9.8125 14.4062 9.4375C14.4062 9.0625 14.0938 8.75 13.7188 8.75Z" fill="#3758F9" />
      <path d="M8.8125 12.8125H6.125C5.75 12.8125 5.4375 13.125 5.4375 13.5C5.4375 13.875 5.75 14.1875 6.125 14.1875H8.8125C9.1875 14.1875 9.5 13.875 9.5 13.5C9.5 13.125 9.1875 12.8125 8.8125 12.8125Z" fill="#3758F9" />
      <path d="M13.7188 12.8125H12.9688C12.5938 12.8125 12.2812 13.125 12.2812 13.5C12.2812 13.875 12.5938 14.1875 12.9688 14.1875H13.7188C14.0938 14.1875 14.4062 13.875 14.4062 13.5C14.4062 13.125 14.0938 12.8125 13.7188 12.8125Z" fill="#3758F9" />
    </svg>
  );
};

export default ReceiptSvgCompontIcon;

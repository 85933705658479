import { SvgIcon } from '@material-ui/core';
import React from 'react';

const TableSvgCompontIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 1.0625H3C1.9375 1.0625 1.0625 1.9375 1.0625 3V18C1.0625 19.0625 1.9375 19.9688 3.03125 19.9688H18.0312C19.0938 19.9688 20 19.0938 20 18V3C19.9688 1.9375 19.0625 1.0625 18 1.0625ZM7.9375 12.2188V7.625H13.0625V12.1875H7.9375V12.2188ZM13.0625 13.625V18.5625H7.9375V13.625H13.0625ZM2.46875 7.625H6.5625V12.1875H2.46875V7.625ZM14.4688 7.625H18.5625V12.1875H14.4688V7.625ZM3 2.46875H18C18.3125 2.46875 18.5625 2.71875 18.5625 3.03125V6.25H2.46875V3C2.46875 2.6875 2.6875 2.46875 3 2.46875ZM2.46875 18V13.625H6.5625V18.5625H3C2.6875 18.5625 2.46875 18.3125 2.46875 18ZM18 18.5625H14.4688V13.625H18.5625V18C18.5625 18.3125 18.3125 18.5625 18 18.5625Z" fill="white" />
    </svg>
  );
};

export default TableSvgCompontIcon;

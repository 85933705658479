import { SvgIcon } from '@material-ui/core';
import React from 'react';

const BackSvgCompontIcon = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.08203" width="47" height="47" rx="23.5" stroke="#111928" />
      <path d="M31.5 23.8945H18.1562L23.4687 18.4883C23.75 18.207 23.75 17.7695 23.4687 17.4883C23.1875 17.207 22.75 17.207 22.4688 17.4883L16 24.0508C15.7187 24.332 15.7187 24.7695 16 25.0508L22.4688 31.6133C22.5938 31.7383 22.7812 31.832 22.9688 31.832C23.1562 31.832 23.3125 31.7695 23.4687 31.6445C23.75 31.3633 23.75 30.9258 23.4687 30.6445L18.1875 25.3008H31.5C31.875 25.3008 32.1875 24.9883 32.1875 24.6133C32.1875 24.207 31.875 23.8945 31.5 23.8945Z" fill="#111928" />
    </svg>
  );
};

export default BackSvgCompontIcon;

import React, { useState, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Hidden,
  Box,
  Grid,
  ImageList,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  List,
  ListItem,
  LinearProgress,
} from '@material-ui/core';
import Header from '../../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import NoOrdersIcon from 'src/assets/no_orders.svg';
import { getSelectedStoreAppClipIds } from 'src/utils';
import VerificationCard from '../components/VerificationCard';
import {
  verificationActionApi,
  verificationListApi,
} from 'src/services/verification';
import { InputAndPopper } from 'src/components';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    background: '#fff',
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  bodyContainer: {
    background: '#f3f4f6',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    height: '100%',
  },
  footer: {
    flex: 0.103,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },
}));

const VerificationOrders = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [cookies] = useCookies();
  const queryClient = useQueryClient();
  const [modalData, setModalData] = useState<object>({});
  const [alertVisible, showAlert] = useState(false);

  const {
    selected_regions,
    selected_retailers,
    active_stores,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);

  let regions = selected_regions.map((region: any) => region.value);
  let store_types = selected_retailers.map((retailer: any) => retailer.value);

  const fetchVerificationData = () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      include_demo_stores,
      include_inactive_stores,
    );

    return verificationListApi.query({
      v1: true,
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      include_demo: include_demo_stores,
      include_inactive: include_inactive_stores,
      token: cookies.app_token,
    });
  };

  const {
    isLoading,
    error,
    isFetching,
    data: verificationListResponse,
  } = useQuery(['VerificationData', active_stores], fetchVerificationData, {
    refetchOnWindowFocus: false,
  });

  let verificationData = verificationListResponse;

  const handleVerificationAction = (payload: any) => {
    return verificationActionApi(get(modalData, 'sessionId', '')).create(
      { v1: true, token: cookies.app_token },
      JSON.stringify(payload),
    );
  };

  const { isLoading: verificationLoading, mutate: verifyAction } = useMutation(
    handleVerificationAction,
    {
      onSuccess: () => {
        showAlert(false);
        queryClient.invalidateQueries('VerificationData');
      },
      onError: error => {
        console.log('Error', { error });
      },
    },
  );

  const alertModal = () => {
    return (
      <Dialog open={alertVisible} onClose={() => showAlert(false)}>
        <DialogTitle>{'Verification'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {get(modalData, 'modalText', '')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              showAlert(false);
            }}
            color="primary"
          >
            {intl.formatMessage({ id: 'cancel' }).toUpperCase()}
          </Button>
          <Button
            onClick={() => {
              showAlert(false);
              verifyAction({ action: get(modalData, 'action', '') });
            }}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({ id: 'confirm' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Paper className={classes.root}>
      <Header
        showStores={true}
        title={
          <FormattedMessage id="verification" defaultMessage="Basket Verification" />
        }
        type="verification"
        items={get(verificationData, 'data.data.sessions', [])}
      />
      <Hidden only={['xs']} implementation="css">
        {verificationLoading && <LinearProgress color="primary" />}
      </Hidden>
      <Hidden only={['lg', 'xl']}>
        <Box className={classes.input}>
          <InputAndPopper
            type="verification"
            items={get(verificationData, 'data.data.sessions', [])}
          />
        </Box>
      </Hidden>
      <Box component="div" className={classes.bodyContainer}>
        {!isLoading &&
          get(verificationData, 'data.data.sessions', []).length === 0 && (
            <Box className={classes.noOrdersContainer}>
              <Box className={classes.noOrders}>
                <Box className={classes.iconContainer}>
                  <img className={classes.icon} src={NoOrdersIcon} alt="" />
                </Box>
                <Typography className={classes.noOrdersText}>
                  No orders for verification so far
                </Typography>
              </Box>
            </Box>
          )}
        {isLoading && (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
        <Box className={classes.body}>
          <Hidden only={['lg', 'xl']}>
            <List>
              {get(verificationData, 'data.data.sessions', []).map(
                (item: any, index: number) => {
                  return (
                    <ListItem key={index}>
                      <VerificationCard
                        item={item}
                        showAlert={showAlert}
                        setModalData={setModalData}
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </Hidden>
          <Hidden only={['xs', 'sm', 'md']}>
            <ImageList
              className={classes.gridContainer}
              cols={1}
              rowHeight={220}
            >
              {get(verificationData, 'data.data.sessions', []).map(
                (item: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={4}
                  >
                    <VerificationCard
                      item={item}
                      showAlert={showAlert}
                      setModalData={setModalData}
                    />
                  </Grid>
                ),
              )}
            </ImageList>
          </Hidden>
        </Box>
      </Box>
      {alertModal()}
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        {verificationLoading && (
          <Box className={classes.mobileLoaderContainer}>
            <Box className={classes.mobileLoader}>
              <CircularProgress
                className={classes.circle}
                size={25}
                thickness={5}
              ></CircularProgress>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        )}
      </Hidden>
    </Paper>
  );
};

export default memo(VerificationOrders);

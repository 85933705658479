import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Typography } from '@material-ui/core';
import Header from '../../components/Header';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DateTimeFilterValues } from '../../components/DateTimeFilter';
import { cashReportApi } from 'src/services/cashReport';
import MaterialTable from 'material-table';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    flex: 1,
    overflow: 'hidden',
    paddingBottom: 100,
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },
  resetFilter: {
    background: '#4180E9',
    borderRadius: 4,
    width: 229,
    height: 40,
    fontWeight: 700,
  },
  detailPanelIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const PriceActivation = () => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const applyFiltersHandler = (filters: DateTimeFilterValues) => {
    filters.order_type = 'scan_and_go';
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        orders: 1,
      },
    });
    dispatch({
      type: 'DATE_TIME_AMOUNT_FILTERS',
      payload: filters,
    });
  };
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const {
    pageNumber,
    pageSize = 10,
    dateTimeAmountFilters: filters,
  } = useSelector((state: any) => state.orders);
  const dateTimeAmountFilters =
    filters && filters.order_type === 'scan_and_go' ? filters : {};
  const { orders: page = 1 } = pageNumber;

  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        orders: value,
      },
    });
  };
  const fetchReportsData = async () => {
    let params: any = {
      v1: true,
      store_id: active_stores[0].store_id,
      app_clip_ids: active_stores[0].app_clip_id,
      page,
      token: cookies.app_token,
      page_size: pageSize,
    };
    return cashReportApi.get(params).then((res: any) => {
      console.log(res.data);
      return res.data || {};
    });
  };
  const { isLoading: isReportsLoading, data: reportsData } = useQuery(
    ['CashReortData', page, active_stores, pageSize],
    fetchReportsData,
    {
      refetchOnWindowFocus: false,
      enabled: active_stores.length === 1,
    },
  );

  const renderReportTable = () => {
    // TODO: to update/add render function for all the columns here for customizing response data to UI needs

    let columns: any = [
      {
        title: <FormattedMessage id="date" defaultMessage="Date" />,
        field: 'business_date',
      },
      {
        title: <FormattedMessage id="register" defaultMessage="Register" />,
        field: 'register',
      },
      {
        title: (
          <FormattedMessage id="totalSales" defaultMessage="Total Sales" />
        ),
        field: 'sales_amount',
        align: 'left',
        render: (rowData: any) => rowData?.sales_amount,
      },
      {
        title: <FormattedMessage id="sales" defaultMessage="Sales" />,
        field: 'sales_count',
        align: 'left',
        render: (rowData: any) => rowData?.sales_count,
      },
      {
        title: <FormattedMessage id="returns" defaultMessage="Returns" />,
        field: 'returns_amount',
        align: 'left',
        render: (rowData: any) => rowData?.returns_amount,
      },
      {
        title: <FormattedMessage id="taxes" defaultMessage="Taxes" />,
        field: 'taxes',
        align: 'left',
        render: (rowData: any) => rowData?.taxes,
      },

      {
        title: (
          <FormattedMessage
            id="numberOfShifts"
            defaultMessage="No. of shifts"
          />
        ),
        field: 'shifts',
        render: (rowData: any) => rowData?.shifts_count,
      },
      {
        title: <></>,
        field: '',
        render: (rowData: any) => (
          <div className={classes.detailPanelIcon}>
            <span>Upload</span>
          </div>
        ),
      },
    ];
    return (
      <MaterialTable
        title=""
        columns={columns}
        data={get(reportsData, 'data', {})}
        // icons={tableIcons}
        isLoading={isReportsLoading}
        components={{
          Toolbar: () => <></>,
        }}
        options={{
          paging: false,
          search: false,
          sorting: false,
          overflowY: 'auto',
          headerStyle: {
            backgroundColor: '#A6C1FF',
            height: 40,
            fontWeight: 400,
            fontSize: 13,
            color: '#222B45',
          },
          padding: 'dense',
          loadingType: 'overlay',
          detailPanelColumnAlignment: 'right',
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData.isShiftsOpen ? '#222B45' : '#F7F9FC',
            height: 40,
            fontWeight: 700,
            fontSize: 12,
            color: rowData.isShiftsOpen ? '#FFFFFF' : '#222B45',
          }),
          tableLayout: 'auto',
        }}
        style={{ marginLeft: 24, marginRight: 24 }}
      />
    );
  };

  return (
    <Box className={classes.root}>
      <Header
        dateTimeAmountFilters={dateTimeAmountFilters}
        applyFiltersHandler={applyFiltersHandler}
        showStores={false}
        title={
          <FormattedMessage id="eodPoslog" defaultMessage="End of day POSlog" />
        }
        subtitle={
          <FormattedMessage
            id="dailyUpload"
            defaultMessage="Uploaded daily at 11 PM"
          />
        }
        order_type="scan_and_go"
      />
      <Box component="div" className={classes.bodyContainer}>
        <Box component="div" className={classes.bodyContainer}>
          {active_stores.length === 1 &&
          get(reportsData, 'data', []).length >= 1 ? (
            <>
              <Box component="div" className={classes.body}>
                {renderReportTable()}
              </Box>
              <Paper className={classes.footer}>
                <Box
                  style={{
                    display: 'inline-flex',
                    position: 'absolute',
                    left: 0,
                  }}
                >
                  <InputLabel
                    htmlFor="age-simple"
                    style={{
                      margin: 'auto 10px auto 30px',
                      fontWeight: 500,
                      color: theme.palette.color_dark_text.main,
                    }}
                  >
                    Items per page:
                  </InputLabel>
                  <Select
                    style={{
                      marginRight: 8,
                      background: 'transparent',
                      borderRadius: 6,
                      border: '1px solid var(--Dark-Dark-7, #D1D5DB)',
                    }}
                    value={pageSize}
                    onChange={(event: any) => {
                      dispatch({
                        type: 'CHANGE_ROWS_PER_PAGE',
                        payload: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </Box>

                <Pagination
                  count={Math.ceil(get(reportsData, 'count', 10) / pageSize)}
                  shape="rounded"
                  size="medium"
                  color="primary"
                  page={page}
                  onChange={handlePagination}
                />
              </Paper>
            </>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#8f9bb3c2',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>
                {active_stores.length > 1 ? (
                  <>
                    <p>Cash reports are not available</p>
                    <p>when multiple stores are selected</p>
                    <p>Please select a single store to continue</p>
                  </>
                ) : (
                  <FormattedMessage
                    id="noReports"
                    defaultMessage="No Reports Available"
                  />
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PriceActivation);

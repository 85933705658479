import { SvgIcon } from '@material-ui/core';
import React from 'react';

const SendSvgCompontIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.625 9.7814C18.5 9.56265 18.3125 9.4064 18.0937 9.2814L3.68747 1.2189C3.43747 1.0939 3.15622 1.0314 2.87497 1.06265C2.59372 1.0939 2.34372 1.18765 2.12497 1.37515C1.90622 1.56265 1.74997 1.81265 1.68747 2.06265C1.59372 2.3439 1.62497 2.62515 1.71872 2.9064L4.40622 10.5001L1.71872 18.0939C1.62497 18.3751 1.62497 18.6564 1.68747 18.9064C1.74997 19.1876 1.90622 19.4064 2.12497 19.5939C2.34372 19.7814 2.59372 19.8751 2.87497 19.9064C2.90622 19.9064 2.96872 19.9064 2.99997 19.9064C3.21872 19.9064 3.46872 19.8439 3.68747 19.7189L18.0937 11.6564C18.3125 11.5314 18.5 11.3751 18.625 11.1564C18.75 10.9376 18.8125 10.6876 18.8125 10.4689C18.8125 10.2501 18.75 10.0002 18.625 9.7814ZM3.06247 2.4689L16.125 9.7814H5.65622L3.06247 2.4689ZM3.06247 18.5314L5.68747 11.2189H16.1562L3.06247 18.5314Z" fill="white" />
    </svg>
  );
};

export default SendSvgCompontIcon;

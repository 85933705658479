import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ArrowRight = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
    >
      <path
        d="M1.20445 15.2865L1.20447 15.2865L1.20721 15.2837L7.76971 8.56494L7.99773 8.33149L7.76916 8.09856L1.20667 1.41106L1.20668 1.41105L1.20445 1.40883C1.05338 1.25775 1.05338 1.03131 1.20445 0.880233C1.35523 0.729454 1.58109 0.729159 1.73216 0.879348C1.73246 0.879643 1.73275 0.879938 1.73305 0.880233L8.79349 8.06566L8.79348 8.06567L8.79555 8.06773C8.94662 8.21881 8.94662 8.44525 8.79555 8.59633L8.79349 8.5984L1.73305 15.7838C1.73276 15.7841 1.73248 15.7844 1.73219 15.7847C1.65773 15.8587 1.55187 15.9049 1.46875 15.9049C1.37385 15.9049 1.29256 15.8791 1.19249 15.8026C1.05349 15.6518 1.05747 15.4335 1.20445 15.2865Z"
        fill="#637381"
        stroke="#637381"
        stroke-width="0.666667"
      />
    </SvgIcon>
  );
};

export default ArrowRight;

import React, { memo, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Hidden,
  Paper,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { DataTable, Header, NavigationTopBar } from '../../components';
import { FormattedMessage, useIntl } from 'react-intl';
import MaterialTable, { Icons } from 'material-table';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { promoListApi } from 'src/services/promotions';
import UploadSvgCompontIcon from 'src/assets/svgIcons/UploadSvgCompontIcon';
import DownloadSvgCompontIcon from 'src/assets/svgIcons/DownloadSvgCompontIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  loader: {
    height: 'auto',
    overflow: 'auto',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  body: {
    flex: 1,
    overflow: 'auto',
    padding: '30px 30px 100px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 100px 0',
    },
  },
  footerMargin: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: 15,
    },
  },
  footerContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      bottom: 0,
      paddingBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    position: 'sticky',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
    borderTop: `1px solid var(--Stroke, #DFE4EA);`,
  },
  detailPanelIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: '5px 0'
    },
    width: '30%',
    marginLeft: '16px',
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },
  buttonRight: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginLeft: 0,
    },
    width: '30%',
    backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    },
  },
  upload: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid #dadada`,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
      paddingLeft: 20,
    },
  },
  uploadContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  buttonUpload: {
    textTransform: 'capitalize',
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      marginRight: 0,
      width: '50%',
    },
  },
  buttonDownload: {
    textTransform: 'capitalize',
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: 'auto',
    },
  },
  buttonDownloadRipple: {
    color: '#fff',
  },
  buttonUploadRipple: {},
  buttonLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  buttonRightRipple: {
    color: (props: any) => `${theme.palette[props.b2bg].main}`,
  },
}));
const tableIcons: Icons = {
  DetailPanel: React.forwardRef((props, ref) => {
    return <></>;
  }),
};
const Promotions = () => {
  const classes = useStyles({
    b1bg: 'blue',
    b2bg: 'blue',
  });
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const theme: any = useTheme();
  const intl = useIntl();
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const { pageNumber, pageSize = 10 } = useSelector(
    (state: any) => state.orders,
  );
  const { cashReport: page = 1 } = pageNumber;
  const inputFile = useRef(null);
  const [loader, setLoader] = useState(false);
  const [uploadData, setUploadData] = useState('');
  const [cleanUpload, setCleanUpload] = useState<boolean>(false);
  const [promoData, setPromoData] = React.useState([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        cashReport: value,
      },
    });
  };

  const appendCurrency = (value: any) => {
    let number = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value);
    return number;
  };

  const fetchPromoData = async (download?) => {
    setLoader(true);
    let params: any = {
      v1: 'easy_store_2',
      store_id: active_stores[0].store_id,
      page,
      page_size: pageSize,
      token: cookies.app_token,
      download: typeof download == 'boolean' ? true : false,
    };
    return promoListApi
      .get(params)
      .then((res: any) => {
        setLoader(false);
        if (res.data.file_url) {
          window.open(res.data.file_url, '_self');
        }
        return res.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const { isLoading: isPromoLoading, data: promosData } = useQuery(
    ['PromotionData', page, active_stores, pageSize],
    fetchPromoData,
    {
      refetchOnWindowFocus: false,
    },
  );
  const { isLoading: loading, mutate: promoMutate } = useMutation(
    ['PromotionData', page, active_stores, pageSize],
    fetchPromoData,
    {
      onSuccess: (promosData: any) => {
        setPromoData(promosData);
        constructTableData(promosData);
        queryClient.invalidateQueries({ queryKey: ['results'] });
        queryClient.refetchQueries({ queryKey: ['results'] });
      },
    },
  );

  useEffect(() => {
    setPromoData(promosData);
    promosData?.results && constructTableData(promosData);
  }, [promosData]);

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };
  const uploadPromoData = async (file?) => {
    setLoader(true);
    let params: any = {
      v1: 'easy_store',
      store_id: active_stores[0].store_id,
      page,
      token: cookies.app_token,
    };
    return promoListApi
      .file(params, {
        store_id: active_stores[0].store_id,
        file: file,
        clean: cleanUpload ? 'True' : 'False',
      })
      .then((res: any) => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: res?.data?.data?.status_msg,
          severity: res?.data?.status === 200 ? 'success' : 'error',
        });
        setTimeout(() => {
          promoMutate({});
        }, 500);
        return res.data || {};
      })
      .catch(e => {
        setLoader(false);
        showToast({
          ...toast,
          visible: true,
          message: e,
          severity: 'error',
        });
      });
  };
  const { isLoading: isUploadLoading, data: reportsData } = useQuery(
    ['PromotionData', page, active_stores, pageSize],
    uploadPromoData,
    {
      refetchOnWindowFocus: false,
    },
  );
  const onChangeFile = async event => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    await setUploadData(file);
    uploadPromoData(file);
  };
  const renderFooterContent = () => {
    return (
      <Box
        className={`${classes.footer} ${classes.footerMargin}`}
        borderTop={0}
        style={{
          position: 'static',
          borderTop: `1px solid var(--Stroke, #DFE4EA)`
        }}
      >
        {active_stores.length === 1 && (
          <Hidden only={['xs', 'sm']}><Button
            className={classes.buttonDownload}
            variant="outlined"
            onClick={() => { fetchPromoData(true) }}
            startIcon={<DownloadSvgCompontIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonDownloadRipple },
            }}
          >
            <FormattedMessage id="refresh" defaultMessage="Download" />
          </Button>
          </Hidden>
        )}
        {active_stores.length === 1 && (
          <>
            <Box className={classes.uploadContainer}>
              <Box
                className={classes.upload}>
                <Checkbox
                  checked={checked}
                  color="primary"
                  inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                  style={{ paddingLeft: 0 }}
                  onClick={() => setCleanUpload(checked)}
                  onChange={() => {
                    setChecked(!checked);
                  }}
                />
                Clean Upload
              </Box>
              <Button
                className={classes.buttonUpload}
                variant="outlined"
                onClick={() => { inputFile.current.click() }}
                startIcon={<UploadSvgCompontIcon />}
                TouchRippleProps={{
                  classes: { root: classes.buttonUploadRipple },
                }}
              >
                <FormattedMessage id="refresh" defaultMessage="Upload" />
              </Button>
            </Box>
            <Hidden only={['md', 'lg', 'xl']}>
              <Button
                className={classes.buttonDownload}
                variant="outlined"
                onClick={() => { fetchPromoData(true) }}
                startIcon={<DownloadSvgCompontIcon />}
                TouchRippleProps={{
                  classes: { root: classes.buttonDownloadRipple },
                }}
              >
                <FormattedMessage id="download" defaultMessage="Download" />
              </Button>
            </Hidden>
          </>
        )}
      </Box>
    )
  };

  const constructTableData = (data: any = {}): any => {
    console.log(data);
    let tableData = [];
    data?.['results']
      // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((promoData: any, index: number) => {
        tableData.push([
          {
            title: <FormattedMessage id="retailer" defaultMessage="Retailer" />,
            value: promoData.retailer,
          },
          {
            title: <FormattedMessage id="promoId" defaultMessage="Promo ID" />,
            value: promoData.promo_id_retailer,
          },
          {
            title: (
              <FormattedMessage
                id="promoFamily"
                defaultMessage="Promo Family"
              />
            ),
            value: promoData.promo_family,
          },
          {
            title: (
              <FormattedMessage
                id="discountType"
                defaultMessage="Discount Type"
              />
            ),
            value: promoData.discount_type,
          },
          {
            title: <FormattedMessage id="totalSales" defaultMessage="Title" />,
            value: promoData.title,
          },
          {
            title: (
              <FormattedMessage id="sales" defaultMessage="Discount Value" />
            ),
            value: promoData.discount_value,
          },
          {
            title: <FormattedMessage id="itemId" defaultMessage="Item ID" />,
            value: promoData.item_id,
          },
          {
            title: (
              <FormattedMessage id="isActive" defaultMessage="Is Active" />
            ),
            value: promoData.is_active,
          },
          {
            title: (
              <FormattedMessage id="isSpecial" defaultMessage="Is Special" />
            ),
            value: promoData.is_special,
          },
          {
            title: (
              <FormattedMessage
                id="requisteDiscoountGroup"
                defaultMessage="Requisite Discount Group Qty"
              />
            ),
            value: promoData.requisite_discount_group,
          },
          {
            title: (
              <FormattedMessage
                id="requisteDiscoountGroupQty"
                defaultMessage="Is Special"
              />
            ),
            value: promoData.requisite_discount_group_qty,
          },
          {
            title: (
              <FormattedMessage id="groupName" defaultMessage="Group Name" />
            ),
            value: promoData.group_name,
          },
          {
            title: (
              <FormattedMessage id="startDate" defaultMessage="Start Date" />
            ),
            value: promoData.start_date_time,
          },
          {
            title: <FormattedMessage id="endDate" defaultMessage="End Date" />,
            value: promoData.end_date_time,
          },
        ]);
      });
    setTableData(tableData);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <NavigationTopBar
          isDetailPage={true}
          arrowPresent={false}
          title={`Promotions`}
          showStores={true}
          refresh={active_stores.length === 1}
          onRefreshClick={() => {
            promoMutate({});
          }}
        />
      </Box>
      <Box component="div" className={classes.bodyContainer}>
        {(isPromoLoading || loader || loading) && (
          <Box className={classes.loader}>
            <CircularProgress color="primary" size={30} />
          </Box>
        )}
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={onChangeFile.bind(this)}
          onClick={event => ((event.target as HTMLInputElement).value = null)}
          style={{ display: 'none' }}
        />
        {active_stores.length === 1 &&
        get(promoData, 'results', []).length >= 1 ? (
          <>
            <Box component="div" className={classes.body}>
              <DataTable tableData={tableData}></DataTable>
            </Box>
            <Box className={classes.footerContainer}>
              <Paper className={classes.footer}>
                  <Hidden only={['xs', 'sm']}>
                    <Box
                      style={{
                        display: 'inline-flex',
                        position: 'absolute',
                        left: 0,
                      }}
                    >
                      <InputLabel
                        htmlFor="age-simple"
                        style={{
                          margin: 'auto 10px auto 30px',
                          fontWeight: 500,
                          color: theme.palette.color_dark_text.main,
                        }}
                      >
                        Items per page:
                      </InputLabel>
                      <Select
                        style={{
                          marginRight: 8,
                          background: 'transparent',
                          borderRadius: 6,
                          border: '1px solid var(--Dark-Dark-7, #D1D5DB)',
                        }}
                        value={pageSize}
                        onChange={(event: any) => {
                          dispatch({
                            type: 'CHANGE_ROWS_PER_PAGE',
                            payload: event.target.value,
                          });
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                      </Select>
                    </Box>
                  </Hidden>

                <Pagination
                  count={Math.ceil(get(promoData, 'count', 10) / pageSize)}
                  shape="rounded"
                  size="medium"
                  color="primary"
                  page={page}
                  onChange={handlePagination}
                />

              </Paper>
              {renderFooterContent()}
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Typography style={{ fontWeight: 'bold' }}>
              {active_stores.length > 1 ? (
                <>
                  <p>
                    Promotions are not available when multiple stores are
                    selected
                  </p>
                  <p>Please select a single store to continue</p>
                </>
              ) : (
                <FormattedMessage
                  id="noReports"
                  defaultMessage="No Reports Available"
                />
              )}
            </Typography>
          </Box>
        )}
      </Box>
      <Snackbar
        open={toast.visible}
        autoHideDuration={3000}
        onClose={hideToast}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={hideToast} severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(Promotions);

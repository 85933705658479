import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  IconButton,
  Typography,
  Button,
  Checkbox,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import RefreshIcon from '../../assets/refresh.svg';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import RefreshSvgComponentIcon from 'src/assets/svgIcons/RefreshSvgCompontIcon';
import DownloadSvgCompontIcon from 'src/assets/svgIcons/DownloadSvgCompontIcon';
import UploadSvgCompontIcon from 'src/assets/svgIcons/UploadSvgCompontIcon';
import { SelectedStores } from '../SelectedStores';
import BackSvgCompontIcon from 'src/assets/svgIcons/BackIcon';
import SendSvgCompontIcon from '../../assets/svgIcons/SendIcon';
import ReceiptSvgCompontIcon from '../../assets/svgIcons/ReceiptIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.color_white.main,
    borderBottom: `1px solid var(--Stroke, #DFE4EA);`,
    boxShadow: 'none',
    borderRadius: 0,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  hideBorder: {
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerAdjust: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 60,
    },
  },

  backArrow: {
    color: theme.palette.color_black.main,
  },

  title: {
    color: theme.palette.color_dark_text.main,
    fontWeight: 600,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },

  titleMargin: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 60,
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    color: theme.palette.color_light_grey.main,
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonColor: {
    background: '#ffaa00',
    '&:hover': {
      backgroundColor: (props: any) => `#c68400`,
    },
  },
  smBtnContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: 'auto',
      marginTop: 10,
      paddingBottom: 10,
    },
  },
  buttonPrint: {
    boxShadow: 'none',
    marginRight: 20,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 4,
    borderWidth: 1,
    [theme.breakpoints.down('xs')]: {
      marginRight: 5,
      marginLeft: 5,
    },
  },
  buttonDownload: {
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
  },
  buttonDownloadRipple: {
    color: '#fff',
  },
  buttonUpload: {
    width: 'auto',
    marginRight: 20,
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: '#fff',
    '&:hover': {
      backgroundColor: (props: any) => '#3366ffe6',
      border: (props: any) => `1px solid #3366ffe6 !important`,
    },
  },
  buttonUploadRipple: {},
  buttonRefresh: {
    marginRight: 20,
    backgroundColor: '#FFF',
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderRadius: 6,
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  buttonRefreshRipple: {
    backgroundColor: 'transparent',
  },
  icon: { width: 20, height: 20 },
  
}));

const NavigationTopBar = ({
  title = null,
  titlekey = null,
  defaultValue = null,
  arrowPresent = true,
  subtitle = null,
  showStores = false,
  refresh = false,
  download = false,
  upload = false,
  onCleanUploadClick = null,
  printReceipt = false,
  onRefreshClick = null,
  onDownloadClick = null,
  onUploadClick = null,
  onPrintReceiptClick = null,
  onEmailReceiptClick = null,
  isDetailPage = false,
  rightComponent = () => <></>,
}: any) => {
  const theme: any = useTheme();
  const width = window.innerWidth < theme.breakpoints.values['sm'] ? 40 : 100;
  const classes = useStyles({ b1bg: 'info', width });
  const history = useHistory();
  const [checked, setChecked] = useState<boolean>(false);
  let newClass = !arrowPresent
    ? clsx(classes.textContainer, classes.titleMargin)
    : classes.textContainer;

  useEffect(() => {
    console.log('uploadData', checked);
  }, [checked]);
  return (
    <Paper className={`${classes.root} ${rightComponent() ? classes.hideBorder : ''}`}>
      <Box className={`${classes.container} ${isDetailPage ? '' : classes.containerAdjust}`}>
        {arrowPresent && (
          <IconButton style={{marginRight: 10,}} onClick={history.goBack}>
            <BackSvgCompontIcon />
          </IconButton>
        )}
        <Box className={newClass}>
          <Typography className={classes.title}>
            {titlekey && defaultValue && (
              <FormattedMessage id={titlekey} defaultMessage={defaultValue} />
            )}
            {title ? title : null}
          </Typography>
          {showStores ? (
                        <SelectedStores
                          source="header"
                          title={
                            <Typography className={classes.subtitle}>
                              {subtitle}
                            </Typography>
                          }
                        />
                      ) : (
                        <Typography className={classes.subtitle}>{subtitle}</Typography>
                      )}
        </Box>

        {printReceipt && (
          <>
            <Hidden only={['xs', 'sm']}>
              <Button
                className={`${classes.buttonPrint} ${classes.buttonColor} bg-primary text-white capitalize`}
                startIcon={<SendSvgCompontIcon />}
                variant="contained"
                onClick={onEmailReceiptClick}
              >
                <FormattedMessage
                  id="emailReceipt"
                  defaultMessage="Email Receipt"
                />
              </Button>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
              <Button
                className={`${classes.buttonPrint} border-solid border-[#3758F9] text-primary bg-transparent capitalize`}
                startIcon={<ReceiptSvgCompontIcon />}
                variant="contained"
                onClick={onPrintReceiptClick}
              >
                <FormattedMessage
                  id="printReceipt"
                  defaultMessage="Print Receipt"
                />
              </Button>
            </Hidden>
          </>
        )}
        {download && width > 40 && (
          <Button
            className={classes.buttonDownload}
            variant="outlined"
            onClick={onDownloadClick}
            startIcon={<DownloadSvgCompontIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonDownloadRipple },
            }}
          >
            <FormattedMessage id="refresh" defaultMessage="Download" />
          </Button>
        )}
        
        {upload && width > 40 && (
          <>
            <Box
              style={{
                marginRight: 20,
                paddingLeft: 20,
                borderLeft: `1px solid #dadada`,
              }}
            >
              <Checkbox
                checked={checked}
                color="primary"
                inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                style={{ paddingLeft: 0 }}
                onClick={onCleanUploadClick(checked)}
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              Clean Upload
            </Box>
            <Button
              className={classes.buttonUpload}
              variant="outlined"
              onClick={onUploadClick}
              startIcon={<UploadSvgCompontIcon />}
              TouchRippleProps={{
                classes: { root: classes.buttonUploadRipple },
              }}
            >
              <FormattedMessage id="refresh" defaultMessage="Upload" />
            </Button>
          </>
        )}
        {upload && width === 40 && (
          <IconButton
            onClick={onUploadClick}
            className={classes.buttonRefresh}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
            <img className={classes.icon} src={RefreshIcon} alt="Upload" />
          </IconButton>
        )}
        {refresh && width > 40 && (
          <Button
            className={classes.buttonRefresh}
            variant="outlined"
            onClick={onRefreshClick}
            startIcon={<RefreshSvgComponentIcon />}
            TouchRippleProps={{
              classes: { root: classes.buttonRefreshRipple },
            }}
          >
          </Button>
        )}
        <Hidden only={['xs', 'sm', 'md']}>{rightComponent()}</Hidden>
        
      </Box>
    </Paper>
  );
};

export default NavigationTopBar;

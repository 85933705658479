import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
  subtitle1: {
    fontSize: '14px',
    color: '#637381',
    fontWeight: 400,
    textAlign: 'left',
  },
  subtitle2: {
    color: theme.palette.color_text_grey.main,
    fontSize: 13,
  },
}));

const SelectedStoresView = ({
  title,
  subtitle = '',
  source = 'drawer',
}: any) => {
  const { active_stores, selected_retailers, selected_regions } = useSelector(
    (state: any) => state.store,
  );
  const classes = useStyles();

  let storeChips = '',
    regionChips = '',
    retailerChips = '';
  if (active_stores.length > 0) {
    if (active_stores.length == 1) {
      storeChips = active_stores[0].retailer;
    } else if (active_stores.length > 1) {
      storeChips =
        active_stores[0].retailer + ' + ' + `${active_stores.length - 1}`;
    }
  } else storeChips = '';

  if (selected_regions.length > 0) {
    if (selected_regions.length == 1) {
      regionChips = selected_regions[0].title;
    } else if (selected_regions.length > 1) {
      regionChips =
        selected_regions[0].title + ' + ' + `${selected_regions.length - 1}`;
    }
  } else regionChips = '';
  if (selected_retailers.length > 0) {
    if (selected_retailers.length == 1) {
      retailerChips = selected_retailers[0].title;
    } else if (selected_retailers.length > 1) {
      retailerChips =
        selected_retailers[0].title +
        ' + ' +
        `${selected_retailers.length - 1}`;
    }
  } else retailerChips = '';

  const displayChipOrText = (value: any) =>
    source === 'header' ? (
      <Typography className={classes.subtitle1}>{value} </Typography>
    ) : (
      <Chip
        label={value}
        style={{
          marginRight: 10,
          marginTop: 10,
          padding: '5px 14px',
          borderRadius: 6,
          lineHeight: 22,
          background: '#3056d314',
          color: '#3758F9',
          fontWeight: 500,
          fontSize: 14,
        }}
      />
    );

  return (
    <Box
      style={{
        display: title ? '' : 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {title ? title : <></>}
      {storeChips && displayChipOrText(storeChips)}{' '}
      {active_stores.length > 1 && (
        <>
          {regionChips && displayChipOrText(regionChips)}
          {retailerChips && displayChipOrText(retailerChips)}
        </>
      )}
      {subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle2}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export const SelectedStores = memo(SelectedStoresView);

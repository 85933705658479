import { SvgIcon } from '@material-ui/core';
import React from 'react';

const RemoveSvgCompontIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1460_19234)">
        <path d="M10 0.894531C4.78125 0.894531 0.5625 5.11328 0.5625 10.332C0.5625 15.5508 4.78125 19.8008 10 19.8008C15.2188 19.8008 19.4688 15.5508 19.4688 10.332C19.4688 5.11328 15.2188 0.894531 10 0.894531ZM10 18.3945C5.5625 18.3945 1.96875 14.7695 1.96875 10.332C1.96875 5.89453 5.5625 2.30078 10 2.30078C14.4375 2.30078 18.0625 5.92578 18.0625 10.3633C18.0625 14.7695 14.4375 18.3945 10 18.3945Z" fill="#E10E0E" />
        <path d="M12.875 7.42578C12.5938 7.14453 12.1563 7.14453 11.875 7.42578L10 9.33203L8.09375 7.42578C7.8125 7.14453 7.375 7.14453 7.09375 7.42578C6.8125 7.70703 6.8125 8.14453 7.09375 8.42578L9 10.332L7.09375 12.2383C6.8125 12.5195 6.8125 12.957 7.09375 13.2383C7.21875 13.3633 7.40625 13.457 7.59375 13.457C7.78125 13.457 7.96875 13.3945 8.09375 13.2383L10 11.332L11.9062 13.2383C12.0312 13.3633 12.2188 13.457 12.4063 13.457C12.5938 13.457 12.7812 13.3945 12.9062 13.2383C13.1875 12.957 13.1875 12.5195 12.9062 12.2383L11 10.332L12.9062 8.42578C13.1562 8.14453 13.1563 7.70703 12.875 7.42578Z" fill="#E10E0E" />
      </g>
      <defs>
        <clipPath id="clip0_1460_19234">
          <rect width="20" height="20" fill="white" transform="translate(0 0.332031)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RemoveSvgCompontIcon;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Hidden,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NavigationTopBar } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import MishipayBackground from '../../assets/new-bg.svg';
import MishipayLogo from '../../assets/mishipay-logo.png';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { resetPasswordApi } from 'src/services/user';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0 !important',
      paddingLeft: '0 !important',
    },
  },
  welcomeContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
  },
  mishipayImg: {
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    top: 90,
    display: 'flex',
    justifyContent: 'center',
  },

  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const classesInput = {
  inputError:
    'w-full bg-transparent rounded-md border-red py-[10px] pl-5 pr-12 text-dark-6 outline-none transition',
  inputNormal:
    'w-full rounded-md border border-[#E9EDF4] bg-transparent px-3 py-[10px] text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-dark',
};

const ResetPassword = () => {
  const history = useHistory();
  const code =
    history.location.search.indexOf('=') !== -1
      ? history.location.search.split('=')[1]
      : null;
  const classes = useStyles();
  const intl = useIntl();
  const [inputs, setInputs] = useState({
    code,
    password1: '',
    password2: '',
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  useEffect(() => {
    let timer: any = null;
    if (success) {
      timer = setTimeout(() => {
        setSuccess(false);
        history.replace('/');
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const handleInputChange = (e: any) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    reset();
  };

  const resetPassword = (formData: any) =>
    resetPasswordApi.create({}, JSON.stringify(formData));

  const { isLoading, mutate, reset } = useMutation(resetPassword, {
    onError: (serverError: any) => {
      setError(true);
      setErrorMessage(serverError);
    },
    onSuccess: async successData => {
      setSuccess(true);
    },
  });

  const handleLogin = () => {
    if (inputs.password1.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyNewPassword' }));
    } else if (inputs.password1.length < 5) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'invalidNewPassword' }));
    } else if (inputs.password2.length === 0) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'emptyConfirmPassword' }));
    } else if (inputs.password2.length < 5) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'invalidConfirmPassword' }));
    } else if (inputs.password1 !== inputs.password2) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'differentPasswords' }));
    } else if (code === null) {
      setError(true);
      setErrorMessage(intl.formatMessage({ id: 'resetCodeNotAvailable' }));
    } else {
      mutate(inputs);
    }
  };

  return (
      <section
        className={`bg-tg-bg py-20 dark:bg-whitesmoke lg:py-[80px] ${classes.root}`}
      >
          {error && (
            <Box className={classes.errorMessageContainer}>
              <Alert
                className={classes.errorMessage}
                variant="filled"
                severity="error"
              >
                {errorMessage}
              </Alert>
            </Box>
          )}
  
          {success && (
            <Box className={classes.errorMessageContainer}>
              <Alert
                className={classes.errorMessage}
                variant="filled"
                severity="success"
              >
                {intl.formatMessage({ id: 'forgotPasswordSuccess' })}
              </Alert>
            </Box>
          )}
        <div className={`mx-auto ${classes.container}`}>
          <div className="bg-white dark:bg-dark-2">
            <div className="flex flex-wrap items-stretch">
              <div className="w-full lg:w-1/2">
                <div className="w-full px-6 py-14 sm:p-[70px] sm:px-12 xl:px-[90px]">
                  <h2 className="mb-10 text-[32px] font-bold text-dark dark:text-white text-left">
                    Reset Password
                  </h2>
                  <form onSubmit={handleLogin} target="_self">
                    <div className="mb-8 w-[100%]">
                      <div className="w-full">
                        <label className="mb-2.5 block text-[16px] text-dark dark:text-white text-left">
                          {intl.formatMessage({ id: 'newPassword' })}
                        </label>
                        <div className="relative">
                          <input
                            name="password1"
                            type="password"
                            onChange={handleInputChange}
                            className={
                              error ? classesInput.inputError : classesInput.inputNormal
                            }
                          />
                          {error && (
                            <span className="absolute top-1/4 right-8 -translate-y-1/2">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                                  fill="#DC3545"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                                  fill="#DC3545"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                                  fill="#DC3545"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="w-full">
                        <label className="mb-2.5 mt-2.5 block text-[16px] text-dark dark:text-white text-left">
                          {intl.formatMessage({ id: 'confirmPassword' })}
                        </label>
                        <div className="relative">
                          <input
                            name="password2"
                            type="password"
                            onChange={handleInputChange}
                            className={
                              error ? classesInput.inputError : classesInput.inputNormal
                            }
                          />
                          {error && (
                            <span className="absolute top-1/4 right-8 -translate-y-1/2">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
                                  fill="#DC3545"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.0013 5.83398C10.4615 5.83398 10.8346 6.20708 10.8346 6.66732V10.0007C10.8346 10.4609 10.4615 10.834 10.0013 10.834C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V6.66732C9.16797 6.20708 9.54106 5.83398 10.0013 5.83398Z"
                                  fill="#DC3545"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.16797 13.3333C9.16797 12.8731 9.54106 12.5 10.0013 12.5H10.0096C10.4699 12.5 10.843 12.8731 10.843 13.3333C10.843 13.7936 10.4699 14.1667 10.0096 14.1667H10.0013C9.54106 14.1667 9.16797 13.7936 9.16797 13.3333Z"
                                  fill="#DC3545"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={handleLogin}
                      disabled={isLoading}
                      className="mb-8 w-full inline-flex h-12 items-center justify-center gap-2.5 cursor-pointer rounded-md bg-primary px-[14px] py-3 text-base font-medium text-white transition hover:bg-opacity-90"
                    >
                      <span>
                        {(isLoading) && (
                          <svg
                            className="animate-spin"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              opacity="0.5"
                              cx="10"
                              cy="10"
                              r="9"
                              stroke="white"
                              stroke-width="2"
                            />
                            <mask id="path-2-inside-1_2527_20936" fill="white">
                              <path d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z" />
                            </mask>
                            <path
                              d="M18.4713 13.0345C18.9921 13.221 19.5707 12.9508 19.7043 12.414C20.0052 11.2042 20.078 9.94582 19.9156 8.70384C19.7099 7.12996 19.1325 5.62766 18.2311 4.32117C17.3297 3.01467 16.1303 1.94151 14.7319 1.19042C13.6285 0.597723 12.4262 0.219019 11.1884 0.0708647C10.6392 0.00512742 10.1811 0.450137 10.1706 1.00319C10.1601 1.55625 10.6018 2.00666 11.1492 2.08616C12.0689 2.21971 12.9609 2.51295 13.7841 2.95511C14.9023 3.55575 15.8615 4.41394 16.5823 5.45872C17.3031 6.50351 17.7649 7.70487 17.9294 8.96348C18.0505 9.89002 18.008 10.828 17.8063 11.7352C17.6863 12.2751 17.9506 12.848 18.4713 13.0345Z"
                              stroke="white"
                              stroke-width="4"
                              mask="url(#path-2-inside-1_2527_20936)"
                            />
                          </svg>
                        )}
                      </span>
                      {isLoading
                        ? 'Loading...'
                        : intl.formatMessage({ id: 'reset' })}
                    </button>
                  </form>
                </div>
              </div>
              <div className={`w-full lg:w-1/2 ${classes.welcomeContainer}`}>
                <div className="relative h-full w-full overflow-hidden bg-[#F59E0B]">
                  <div className="flex h-full items-end p-8 sm:p-14">
                  <img src='https://res.cloudinary.com/dlfzyqsef/image/upload/v1552548613/logo-white_fi0ggt.png' className={`flex sm:pb-[20px] xs:pb-[20px] ${classes.mishipayImg}`} />
                    <div>
                      <span className="absolute left-0 top-0">
                        <svg
                          width="415"
                          height="355"
                          viewBox="0 0 415 355"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M415 107.5C415 244.19 304.19 355 167.5 355C30.8095 355 -80 244.19 -80 107.5C-80 -29.1905 30.8095 -140 167.5 -140C304.19 -140 415 -29.1905 415 107.5Z"
                            fill="url(#paint0_linear_1179_8)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1179_8"
                              x1="167.5"
                              y1="-140"
                              x2="167.5"
                              y2="355"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0.177083"
                                stopColor="white"
                                stopOpacity="0.16"
                              />
                              <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="absolute left-0 top-0">
                        <svg
                          width="177"
                          height="354"
                          viewBox="0 0 177 354"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M177 177C177 274.754 97.7544 354 0 354C-97.7544 354 -177 274.754 -177 177C-177 79.2456 -97.7544 0 0 0C97.7544 0 177 79.2456 177 177Z"
                            fill="url(#paint0_linear_1179_7)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1179_7"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="354"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0.177083"
                                stopColor="white"
                                stopOpacity="0.2"
                              />
                              <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <span className="absolute bottom-16 right-20">
                        <svg
                          width="85"
                          height="85"
                          viewBox="0 0 85 85"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.5 -1.85773e-06C65.9721 -2.88373e-06 85 19.0279 85 42.5C85 65.9721 65.9721 85 42.5 85C19.0279 85 -8.31736e-07 65.9721 -1.85773e-06 42.5C-2.88373e-06 19.0279 19.0279 -8.31736e-07 42.5 -1.85773e-06Z"
                            fill="url(#paint0_linear_1179_6)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_1179_6"
                              x1="-1.85774e-06"
                              y1="42.5"
                              x2="85"
                              y2="42.5"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop
                                offset="0.177083"
                                stopColor="white"
                                stopOpacity="0.16"
                              />
                              <stop
                                offset="1"
                                stopColor="white"
                                stopOpacity="0"
                              />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    // <Paper className={classes.paperContainer}>
    //   <Hidden only={['xs']} implementation="css">
    //     {isLoading && <LinearProgress color="primary" />}
    //   </Hidden>
    //   {error && (
    //     <Box className={classes.errorMessageContainer}>
    //       <Alert
    //         className={classes.errorMessage}
    //         variant="filled"
    //         severity="error"
    //       >
    //         {errorMessage}
    //       </Alert>
    //     </Box>
    //   )}

    //   {success && (
    //     <Box className={classes.errorMessageContainer}>
    //       <Alert
    //         className={classes.errorMessage}
    //         variant="filled"
    //         severity="success"
    //       >
    //         {intl.formatMessage({ id: 'resetPasswordSuccess' })}
    //       </Alert>
    //     </Box>
    //   )}
    //   <Box className={classes.container}>
    //     <Box className={classes.innerContainer}>
    //       <NavigationTopBar
    //         titlekey="resetPassword"
    //         defaultValue="Reset password"
    //         arrowPresent={false}
    //       />
    //       <Box className={classes.innerContainer1}>
    //         <Box component="div" className={classes.logoContainer}>
    //           <img
    //             src={MishipayLogo}
    //             alt="Mishipay Logo"
    //             className={classes.logo}
    //           />
    //         </Box>
    //       </Box>
    //       <Typography className={classes.forgotPassword}>
    //         {intl.formatMessage({ id: 'resetPasswordTitle' })}
    //       </Typography>
    //       <Box className={classes.innerContainer2}>
    //         <TextField
    //           className={classes.textField}
    //           name="password1"
    //           type="password"
    //           placeholder={intl.formatMessage({ id: 'newPassword' })}
    //           onChange={handleInputChange}
    //         />

    //         <TextField
    //           className={classes.textField}
    //           name="password2"
    //           type="password"
    //           placeholder={intl.formatMessage({ id: 'confirmPassword' })}
    //           onChange={handleInputChange}
    //         />

    //         <Box className={classes.buttonContainer}>
    //           <Button
    //             className={classes.buttonLogin}
    //             variant="contained"
    //             color="primary"
    //             onClick={handleLogin}
    //             disabled={isLoading}
    //           >
    //             <FormattedMessage
    //               id="reset"
    //               defaultMessage="RESET"
    //             ></FormattedMessage>
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Box>
    //     <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
    //       {isLoading && (
    //         <Box className={classes.mobileLoaderContainer}>
    //           <Box className={classes.mobileLoader}>
    //             <CircularProgress
    //               className={classes.circle}
    //               size={25}
    //               thickness={5}
    //             ></CircularProgress>
    //             <Typography>Loading...</Typography>
    //           </Box>
    //         </Box>
    //       )}
    //     </Hidden>
    //   </Box>
    // </Paper>
  );
};

export default ResetPassword;

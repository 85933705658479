import { SvgIcon } from '@material-ui/core';
import React from 'react';

const WarningSvgCompontIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4062 12.988L12.7187 2.22754C12.1875 1.50879 11.375 1.10254 10.5 1.10254C9.59373 1.10254 8.78123 1.50879 8.28123 2.22754L1.59373 12.988C0.968731 13.8317 0.874981 14.9255 1.34373 15.863C1.81248 16.8005 2.74998 17.3942 3.81248 17.3942H17.1875C18.25 17.3942 19.1875 16.8005 19.6562 15.863C20.125 14.9567 20.0312 13.8317 19.4062 12.988ZM18.4062 15.238C18.1562 15.7067 17.7187 15.988 17.1875 15.988H3.81248C3.28123 15.988 2.84373 15.7067 2.59373 15.238C2.37498 14.7692 2.40623 14.238 2.71873 13.8317L9.40623 3.07129C9.65623 2.72754 10.0625 2.50879 10.5 2.50879C10.9375 2.50879 11.3437 2.69629 11.5937 3.07129L18.2812 13.8317C18.5937 14.238 18.625 14.7692 18.4062 15.238Z" fill="white" />
      <path d="M10.4999 6.91504C10.1249 6.91504 9.78119 7.22754 9.78119 7.63379V11.04C9.78119 11.415 10.0937 11.7588 10.4999 11.7588C10.9062 11.7588 11.2187 11.4463 11.2187 11.04V7.60254C11.2187 7.22754 10.8749 6.91504 10.4999 6.91504Z" fill="white" />
      <path d="M10.4999 12.582C10.1249 12.582 9.78119 12.8945 9.78119 13.3008V13.457C9.78119 13.832 10.0937 14.1758 10.4999 14.1758C10.9062 14.1758 11.2187 13.8633 11.2187 13.457V13.2695C11.2187 12.8945 10.8749 12.582 10.4999 12.582Z" fill="white" />
    </svg>
  );
};

export default WarningSvgCompontIcon;

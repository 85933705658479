import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ArrowRight from 'src/assets/svgIcons/ArrowRight';
import SearchIcon from 'src/assets/svgIcons/SearchIcon';
import { ordersApi } from 'src/services/orders';
import {
  dateTimeFormat,
  getSelectedStoreAppClipIds,
  goToOrderDetails,
} from 'src/utils';
import { Alert } from '@material-ui/lab';
import TuneIcon from '../../assets/tune.svg';
import DateTimeFilter from '../DateTimeFilter';

const useStyles = makeStyles((theme: any) => ({
  mainContainer: {
    display: 'flex',
    [theme.breakpoints.between('xs', 'md')]: {
      paddingBottom: 10,
    },
  },
  input: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: 323,
    height: 50,
    display: 'flex',
    alignSelf: 'center',
    background: '#fff',
    border: '1px solid #00000026',
    borderRight: 0,
  },
  searchby: {
    height: 50,
    fontSize: 12,
    padding: '0 18px 0 16px',
    background: '#fff',
    border: '1px solid #00000026',
    borderLeft: 0,
    '& .MuiSelect-select': {
      paddingRight: 10,
    },
    '& .MuiSelect-icon': {
      right: 5,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 14px 0 8px',
      '& .MuiSelect-select': {
        paddingRight: 5,
      },
      '& .MuiSelect-icon': {
        right: 0,
      },
    },
  },
  separatorBox: {
    height: 'auto',
    display: 'flex',
    background: '#fff',
  },
  separator: {
    height: '60%',
    borderRight: '1px #00000026 solid',
    margin: 'auto',
  },
  errorMessageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
    [theme.breakpoints.between('lg', 'xl')]: {
      left: 150,
    },
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5000,
  },
  dateTimeFilterIcon: {
    borderRadius: 6,
    marginLeft: 9,
    width: 48,
    height: 48,
    '&:hover': {
      backgroundColor: '#222B45',
    },
  },
  filterIndicator: {
    position: 'absolute',
    top: -7,
    right: 4,
    borderRadius: '50%',
    background: '#4180E9',
    height: 12,
    width: 12,
  },
  errorMessage: { maxWidth: 343, flex: 1, fontWeight: 'bold' },
}));

const InputAndPopper = ({
  type = null,
  items = null,
  order_type = null,
  dateTimeAmountFilters = {},
  applyFiltersHandler,
}: any) => {
  const classes = useStyles();
  const {
    selected_regions,
    selected_retailers,
    active_stores,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const intl = useIntl();
  const history = useHistory();
  const [input, setInput] = useState('');
  const [cookies] = useCookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [orderData, setOrderData] = React.useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [showFilters, setShowFilters] = useState(false);
  let searchByTransactionEnabled = active_stores.find((store: any) =>
    get(store, 'features_applicable.search_by_transaction_id', false),
  );
  const searchBy = [
    { value: 'order_id', title: 'Order ID' },
    { value: 'transaction_id', title: 'Transaction ID' },
    // { value: 'sco_id', title: 'SCO ID' },
  ];
  const [search, setSearchFilter] = React.useState('order_id');
  const [searchName, setSearchNameFilter] = React.useState('Order ID');
  const closeFiltersHandler = () => {
    setShowFilters(false);
  };

  useEffect(() => {
    let timer: any = null;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
        setErrorMessage('Error');
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  const onChangeText = (e: any) => {
    setAnchorEl(e.currentTarget);
    setInput(e.target.value);
  };

  const checkIfNotFlyingTigerStores = () =>
    active_stores.filter(
      (store: any) => store.store_type !== 'FlyingTigerStoreType',
    );

  const modifySearchString = (searchString: string) => {
    if (checkIfNotFlyingTigerStores()) {
      return searchString;
    } else if (
      searchString.startsWith('100') ||
      searchString.startsWith('1000') ||
      searchString.startsWith('10000') ||
      searchString.startsWith('100000')
    ) {
      return searchString;
    } else {
      if (searchString.length === 7) {
        return '10' + searchString;
      } else if (searchString.length === 6) {
        return '100' + searchString;
      } else if (searchString.length === 5) {
        return '1000' + searchString;
      } else if (searchString.length === 4) {
        return '10000' + searchString;
      } else if (searchString.length === 3) {
        return '100000' + searchString;
      } else return searchString;
    }
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      include_demo_stores,
      include_inactive_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let searchString = modifySearchString(input);
    searchString !== input && setInput(searchString);
    let params: any = {
      v1: 'new_base_v4',
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      purchase_type: order_type,
      page: 1,
      token: cookies.app_token,
      include_demo: include_demo_stores,
      include_inactive: include_inactive_stores,
      page_size: 50,
    };
    search === 'transaction_id'
      ? (params.transaction_id_poslog = searchString)
      : (params.o_id = searchString);
    return ordersApi.query(params);
  };

  const { isLoading: orderFetchLoading, mutate: fetchOrders } = useMutation(
    fetchOrdersData,
    {
      onSuccess: (data: any) => {
        setInput('');
        if (get(data, 'data.data', []).length > 1) {
          setOpen(true);
          setOrderData(get(data, 'data.data', []));
        } else {
          if (get(data, 'data.data', []).length === 0) {
            setError(true);
            setErrorMessage('No orders found');
          } else {
            goToOrderDetails({
              order_id: get(data, 'data.data[0].order_id', ''),
              type:
                order_type === 'click_and_collect'
                  ? 'click_and_collect'
                  : 'orders',
              from: 'orders',
              history,
            });
          }
        }
      },

      onError: error => {
        setError(true);
        setErrorMessage('Error fetching orders');
      },
    },
  );

  const onPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      if (type === 'verification' && items) {
        let item = items.filter((item: any) => item.code == input)[0];
        if (item) {
          setInput('');
          history.push({
            pathname: `/verificationDetails/`,
            state: {
              items: JSON.stringify(item),
            },
          });
        } else {
          setError(true);
          setErrorMessage('No verification orders found');
        }
      } else {
        fetchOrders();
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const goTo = (order_id: any) => {
    handleClose();
    goToOrderDetails({
      order_id,
      type: order_type === 'click_and_collect' ? 'click_and_collect' : 'orders',
      from: 'orders',
      history,
    });
  };

  return (
    <>
      <Box className={classes.mainContainer}>
        <Input
          style={{ borderRadius: '4px 0 0 4px' }}
          className={classes.input}
          fullWidth={true}
          name="search"
          onKeyDown={onPressEnter}
          placeholder={
            type === 'verification'
              ? intl.formatMessage({ id: 'searchVerificationId' })
              : `Search ${searchName}`
          }
          onChange={onChangeText}
          autoComplete="off"
          value={input}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            orderFetchLoading && (
              <InputAdornment position="end">
                <CircularProgress size={18} color="primary" />
              </InputAdornment>
            )
          }
        />
        {searchByTransactionEnabled && (
          <>
            <Box className={classes.separatorBox}>
              <Box className={classes.separator}></Box>
            </Box>
            <Box>
              <Select
                className={classes.searchby}
                style={{
                  borderRadius: searchByTransactionEnabled
                    ? '0 4px 4px 0'
                    : '4px',
                }}
                value={search}
                onChange={(event: any) => {
                  setSearchFilter(event.target.value);
                  setSearchNameFilter(
                    event.target.value === 'order_id'
                      ? 'Order ID'
                      : 'Transaction ID',
                  );
                }}
              >
                {searchBy.map((item: any, index: number) => (
                  <MenuItem key={index} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </>
        )}
        {(order_type === 'kiosk' ||
          order_type === 'scan_and_go' ||
          order_type === 'mpos' ||
          order_type === 'cashier_kiosk') && (
            <IconButton
              className={`bg-primary ${classes.dateTimeFilterIcon}`}
              onClick={() => {
                setShowFilters(prevState => !prevState);
              }}
            >
              {dateTimeAmountFilters.isApplied && (
                <span className={classes.filterIndicator} />
              )}
              <img src={TuneIcon} alt="filters" />
            </IconButton>
          )}
      </Box>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
          backgroundColor: 'white',
          zIndex: 5000,
          width: 385,
          marginLeft: 20,
          marginTop: 20,
          marginRight: 20,
        }}
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  style={{
                    height: '70vh',
                    overflow: 'auto',
                    borderRadius: '6px',
                    boxShadow: '0px 1px 3px 0px rgba(166, 175, 195, 0.40)',
                  }}
                >
                  {orderData.map((item: any, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => goTo(item.order_id)}
                      className="pt-[6px] bg-[#FFF] text-[#222b45] hover:bg-[#3758f90d] hover:text-primary"
                    >
                      <Box style={{ padding: 5 }}>
                        <Typography
                          style={{
                            fontSize: 13,
                            fontWeight: 'bold',
                          }}
                        >{`#${item.o_id} - ${item.date
                            ? `${moment(item.date).format(
                              dateTimeFormat(item.store_type),
                            )} UTC(${moment(item.date)
                              .utc()
                              .format('HH:mm')})`
                            : ''
                          }`}</Typography>
                        <Typography style={{ fontSize: 12, color: '#8f9bb3' }}>
                          {item.store_name}
                        </Typography>
                        <Box
                          style={{ position: 'absolute', right: 0, top: 18 }}
                        >
                          <ArrowRight />
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {error && (
        <Box className={classes.errorMessageContainer}>
          <Alert
            className={classes.errorMessage}
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Box>
      )}
      {showFilters && (
        <DateTimeFilter
          {...dateTimeAmountFilters}
          closeFiltersHandler={closeFiltersHandler}
          applyFiltersHandler={applyFiltersHandler}
        />
      )}
    </>
  );
};

export default InputAndPopper;

import { SvgIcon } from '@material-ui/core';
import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.35 13.5072L11.225 10.1572C12.925 7.83223 12.75 4.50723 10.625 2.40723C9.5 1.28223 8 0.657227 6.4 0.657227C4.8 0.657227 3.3 1.28223 2.175 2.40723C-0.149998 4.73223 -0.149998 8.53223 2.175 10.8572C3.3 11.9822 4.8 12.6072 6.4 12.6072C7.925 12.6072 9.35 12.0322 10.475 11.0072L14.65 14.3822C14.75 14.4572 14.875 14.5072 15 14.5072C15.175 14.5072 15.325 14.4322 15.425 14.3072C15.625 14.0572 15.6 13.7072 15.35 13.5072ZM6.4 11.4822C5.1 11.4822 3.9 10.9822 2.975 10.0572C1.075 8.15723 1.075 5.08223 2.975 3.20723C3.9 2.28223 5.1 1.78223 6.4 1.78223C7.7 1.78223 8.9 2.28223 9.825 3.20723C11.725 5.10723 11.725 8.18223 9.825 10.0572C8.925 10.9822 7.7 11.4822 6.4 11.4822Z" fill="#6B7280" />
    </svg>
  );
};

export default SearchIcon;

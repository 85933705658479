import { SvgIcon } from '@material-ui/core';
import React from 'react';

const RefreshSvgComponentIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.62501 8.64453C2.71876 8.64453 2.84376 8.61328 2.93751 8.58203L6.40626 7.36328C6.78126 7.23828 6.96876 6.83203 6.84376 6.45703C6.71876 6.08203 6.31251 5.89453 5.93751 6.01953L3.78126 6.76953C4.81251 4.08203 7.43751 2.26953 10.4375 2.26953C13.7188 2.26953 16.5625 4.45703 17.375 7.58203C17.4688 7.95703 17.8438 8.17578 18.2188 8.08203C18.5938 7.98828 18.8125 7.61328 18.7188 7.23828C17.75 3.48828 14.3438 0.863281 10.4063 0.863281C6.90626 0.863281 3.81251 2.92578 2.53126 6.01953L1.87501 4.14453C1.75001 3.76953 1.34376 3.58203 0.968756 3.70703C0.593756 3.83203 0.406256 4.23828 0.531256 4.61328L1.71876 8.01953C1.90626 8.42578 2.25001 8.64453 2.62501 8.64453Z" fill="#3758F9" />
      <path d="M19.4063 15.1133L18.125 11.7695C18.0313 11.5508 17.875 11.3633 17.6563 11.2695C17.4375 11.1758 17.1875 11.1758 16.9688 11.2695L13.5313 12.582C13.1563 12.707 13 13.1133 13.125 13.4883C13.25 13.8633 13.6563 14.0195 14.0313 13.8945L16.1875 13.082C14.9375 15.3633 12.5 16.8633 9.78126 16.8633C6.68751 16.8633 3.96876 14.9258 3.00001 12.0508C2.87501 11.6758 2.46876 11.4883 2.12501 11.6133C1.75001 11.7383 1.56251 12.1445 1.68751 12.4883C2.84376 15.9258 6.09376 18.2383 9.78126 18.2383C13 18.2383 15.9063 16.457 17.375 13.707L18.0938 15.6133C18.1875 15.8945 18.4688 16.0508 18.75 16.0508C18.8438 16.0508 18.9063 16.0508 19 15.9883C19.375 15.8945 19.5313 15.4883 19.4063 15.1133Z" fill="#3758F9" />
    </svg>
  );
};

export default RefreshSvgComponentIcon;
